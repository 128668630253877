class DebugValues {
  apiUrl = process.env.VUE_APP_URLAPI;
}

class ProdValue {
  apiUrl = "https://www.agnhs.ch/api";
}

class EnvironmenConfiguration {
  getConfiguration = function () {
    if (process.env.NODE_ENV === "production") {
      console.log("Using Prod values!");
      return new ProdValue();
    }
    return new DebugValues();
  };
}

export default EnvironmenConfiguration;
