// depricated - replaced by "Veranstaltungen"
import data from "../../../assets/data/data.js";
const EventListComponent = {
  data: function () {
    return {
      veranstaltungList: [],
    };
  },

  created: function () {
    console.log('Warning - depricated component in use! (event-list.component.js)');
    var today = new Date();
    today.setDate(today.getDate() - 0.5);
    this.veranstaltungList = data.veranstaltungen
      .sort((d, o) => {
        return new Date(d.datum) - new Date(o.datum);
      })
      .filter((v) => {
        return new Date(v.datum) > today;
      });
  },
};

export default EventListComponent;
