import Axios from "axios";
import EnvironmenConfiguration from "../config";

class FeedbackServiceClass {
  configuration = new EnvironmenConfiguration().getConfiguration();

  sendFeedback = function (who, subject, text, sender) {
    let data = {
      Subject: subject,
      WhoId: who,
      Text: text,
      Sender: sender,
    };

    return Axios.post(
      `/Feedback`,
      JSON.stringify(data),
      { headers: { "Content-Type": "application/json" } }
    ).then(
      (r) => {
        console.log(r);
        return true;
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  };
}

export default FeedbackServiceClass;
