import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import AuthenticateServiceClass from '../services/authenticate.service'
import ConnectionServiceClass from '../services/connect.service'
import FeedbackServiceClass from '../services/feedback.service'
import JoinServiceClass from '../services/join.service'
import NewsServiceClass from '../services/news.service'
import SammlungServiceClass from '../services/sammlungagnhs.service'
import LogServiceClass from '../services/log.service'
import UserServiceClass from '../services/users.service'
import createPersistedState from "vuex-persistedstate";
import FtpContentServiceClass from "../services/ftpcontent.service";
import router from '../router/index'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        authenticateService: new AuthenticateServiceClass(),
        connectionService: new ConnectionServiceClass(),
        feedbackService: new FeedbackServiceClass(),
        joinService: new JoinServiceClass(),
        sammlungService: new SammlungServiceClass(),
        newsService: new NewsServiceClass(),
        logService: new LogServiceClass(),
        userService: new UserServiceClass(),
        ftpcontentService: new FtpContentServiceClass(),
        UserInfo: {},
        AuthToken: ''
    },
    mutations: {
        user(state, user) {
            console.log(user);
            state.UserInfo = user;
        },
        authToken(state, authToken) {
            state.AuthToken = authToken;
        }
    },
    getters: {
        getUserInfo: (state) => {
            return state.UserInfo;
        },
        getAuthToken: (state) => {
            console.log('get Auth Token');
            console.log(state.AuthToken);
            return state.AuthToken;
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [createPersistedState()]
})



Axios.interceptors.request.use(req => {
    console.log('Axios interceptor');
    req.headers.authorization = `Bearer ${store.state.AuthToken}`;
    req.responseType = 'json';
    // console.log(req);
    return req;
});


Axios.interceptors.response.use(
    response => {
        //console.log(response)
        return response;
    }
    , (error) => {
        console.log(error);
        if (error.response.status === 401) {
            router.push('/Login');
        } else {
            return error.response;
        }
    })
export default store;