const ParagraphEditComponent = {
  /**
   *  Paragraph
   *
   *  */
  props: ["paragraph"],
  computed: {
    /**
     * returns if the paragraph is marked as deleted
     * @returns bool - is the property of the paragraph.delete true
     */
    isDelete: function () {
      return this.paragraph.delete;
    },
    /**
     *
     * @returns string - the (bootstrap) variant to use
     */
    deleteVariant: function () {
      return this.paragraph.delete ? "info" : "danger";
    },
    /**
     * @returns string - the tooltip for the delete button
     */
    deleteTooltip: function () {
      return this.paragraph.delete ? "wiederherstellen" : "löschen";
    },
    /**
     *
     * @returns bool - is the env set to debug
     */
    debug: function () {
      return process.env.VUE_APP_ISBETA;
    },
  },
};

export default ParagraphEditComponent;
