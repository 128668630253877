import Axios from "axios";
import EnvironmenConfiguration from "../config";

class JoinServiceClass {
  configuration = new EnvironmenConfiguration().getConfiguration();

    sendJoin = function (name, street, number, city, email, newsletter) {
        let data = {
            Name: name,
            Street: street,
            StreetNumber: number,
            City: city,
            Email: email,
            Newsletter: newsletter,
        };

        return Axios.post(
            `/Membership`,
            JSON.stringify(data),
            { headers: { "Content-Type": "application/json" } }
        ).then(
            (r) => {
                console.log(r);
                return true;
            },
            (err) => {
                console.log(err);
                return false;
            }
        );
    };
}

export default JoinServiceClass;
