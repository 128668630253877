import Vue from 'vue'
import VueRouter from 'vue-router'
import Start from '../components/start/start.component.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Start
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../components/login/login.vue')
    },
    {
        path: '/ornithologie',
        name: 'ornithologie',
        component: () => import('../components/naturschutz/ornithologie/ornithologie.vue')
    },
    {
        path: '/mnis',
        name: 'mnis',
        component: () => import('../components/naturschutz/mnis/mnis.vue')
    },
    {
        path: '/fledermaus',
        name: 'fledermaus',
        component: () => import('../components/naturschutz/fledermaus/fledermaus.vue')
    },
    {
        path: '/heimat',
        name: 'heimat',
        component: () => import('../components/heimatschutz/heimatschutz.vue')
    },
    {
        path: '/PubJahresberichte',
        name: 'PubJahresberichte',
        component: () => import('../components/publikationen/jahresberichte/pubJahresberichte.vue')
    },
    {
        path: '/Photosammlung',
        name: 'Photosammlung',
        component: () => import('../components/publikationen/photosammlung/photosammlung.vue')
    },
    {
        path: '/PublikationenDenkmal',
        name: 'PublikationenDenkmal',
        component: () => import('../components/publikationen/denkmal/denkmal.vue')
    }, {
        path: '/statuten',
        name: 'Statuten',
        component: () => import('../components/verein/statuten/statuten.vue')
    },
    {
        path: '/ziele',
        name: 'Vereinsziele',
        component: () => import('../components/verein/ziele/ziele.vue')
    },
    {
        path: '/PublikationenNaturschutz',
        name: 'PublikationenNaturschutz',
        component: () => import('../components/publikationen/naturschutz/pubNaturschutz.vue')
    },
    {
        path: '/Bankverbindung',
        name: 'Bankverbindung',
        component: () => import('../components/verein/bank/bank.vue')
    },
    {
        path: '/Feedback',
        name: 'Feedback',
        component: () => import('../components/feedback/feedback.vue')
    },
    {
        path: '/Mitmachen',
        name: 'Mitmachen',
        component: () => import('../components/mitmachen/mitmachen.component.vue')
    },
    {
        path: '/Pflegegebiete/Uebersicht',
        name: 'PflegeUebersicht',
        component: () => import('../components/pflege/Uebersicht/uebersicht.component.vue')
    },
    {
        path: '/Pflegegebiete/Detail/:name',
        component: () => import('../components/pflege/detail/pflegedetail.component.vue')
    },
    {
        path: '/Veranstaltung/Detail/:id',
        component: () => import('../components/event/event-detail.component.vue')
    },
    {
        path: '/vorstand',
        component: () => import('../components/vorstand/vorstand.component.vue')
    },
    {
        path: '/sammlungagnhs',
        component: () => import('../components/publikationen/sammlungagnhs/sammlungagnhs.component.vue')
    },
    {
        path: '/NewsOverview',
        component: () => import('../components/news/news-overview.component.vue')
    },
    {
        path: '/News/Detail/:id',
        component: () => import('../components/news/news-detail.component.vue')
    },
    {
        name: 'NewsEdit',
        path: '/News/Edit/:id?',
        component: () => import('../components/news/edit/news-edit.component.vue')
    },
    {
        path: '/Users/List',
        component: () => import('../components/useradmin/list/userlist.component.vue')
    },
    {
        path: '/Users/Detail/:id?',
        component: () => import('../components/useradmin/detail/userdetail.component.vue')
    },
    {
        path: '/Users/Delete/:id',
        component: () => import('../components/useradmin/delete/userdelete.component.vue')
    },
    {
        path: '/Users/Register',
        component: () => import('../components/useradmin/register/userregister.compontent.vue')
    },
    {
        path: '/Ftp/List/:path?',
        component: () => import('../components/ftp/list/ftplist.component.vue')
    },
    {
        path: '/JV2022',
        component: () => import('../components/JV2022/jv2022.component.vue')
    },
    {
        path: '/Playground',
        component: () => import('../components/playground/playground.component.vue')
    },
    {
        path: '/Vereinsgeschichte',
        component: () => import('../components/club/clubhistory.vue')
    },
    {
        path: '/Register',
        component: () => import('../components/register/register.component.vue')
    },
    {
        path: '/Veranstaltungen',
        component: () => import('../components/veranstaltungen/veranstaltungen.component.vue')
    },
    {
        path: '/Pflegegebiete',
        name: 'Pflegegebiete',
        component: () => import('../components/pflegegebiete/pflegegebiete.component.vue')
    },
    {
        path: '/Datenschutzerklaerung',
        name: 'PrivacyStatement',
        component: ()=>import('../components/verein/privacystatement/privacystatement.component.vue')
    }

]

const router = new VueRouter({
    routes
})

export default router
