import axios from "axios";
import Config from "../config";

class ConnectServiceClass {
  configuration = new Config().getConfiguration();

  getConnectionStatus = function () {
      console.log('ConnectService.getConnenctionStatus()');

    try {
      return axios.get(`/Connect`).then(
        (resp) => {
              try {
                  console.log(resp.data);
                  return resp.data;
                } catch (ex) {
                  return {
                      status: 0,
                  };
          }
        },
        () => {
            return {
                status: 0,
            };
        }
      );
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };

  checkAuthentication = function () {
    try {
      return axios
        .get(`/Connect/checkAuth`)
        .then((resp) => {
          try {
            console.log(resp.data.status == 1);
            return resp.data.status == 1;
          } catch (ex) {
            return false;
          }
        });
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };
}

export default ConnectServiceClass;
