export default {
  data: function () {
    return {
      stuff: [
        { id: 1, baz: false },
        { id: 2, baz: false },
      ],
    };
  },
  methods: {
    doSomething: function () {
      this.stuff[0].baz = true;
    },
  },
};
