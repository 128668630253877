import Vue from "vue";
import { Icon } from "leaflet";
import { LMap, LWMSTileLayer, LMarker, LPopup } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import data from "../../assets/data/data.js";
import pflegedata from "../../assets/data/pflegegebiete.data.js";
import agnhsDate from "../../helper/AgnhsDate";
import MapConfiguration from "../../helper/MapConfiguration"

Vue.component("l-map", LMap);
Vue.component("l-wms-tile-layer", LWMSTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-popup", LPopup);

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const StartComponent = {
    data: function () {
        var mapConfig = new MapConfiguration();
        return {
            
            hotspotList: [],
            veranstaltungList: [],
            newsList: [],
            newsIsLoading: true,
            url: mapConfig.url,
            layers: mapConfig.layers,
            mapOptions: mapConfig.mapOptions,
            agnhsDate: new agnhsDate()
        };
    },
    mounted: function () {
        // list of hotspots
        this.hotspotList = pflegedata;

        // Veranstaltugen
        var today = new Date();
        today.setDate(today.getDate() - 0.5);
        this.veranstaltungList = data.veranstaltungen
            .sort((d, o) => {
                return new Date(d.datum) - new Date(o.datum);
            })
            .filter((v) => {
                return new Date(v.datum) > today;
            }).slice(0, 3);
        console.log(this.veranstaltungList)
        
    },
    created: async function () {
        console.log("start-component.js loaded");
        this.newsList = await this.$store.state.newsService.getNews(3);
    },
};

export default StartComponent;