class PagedItemList {
  Page = 1
  TotalCount = 1
  GrandTotal = 1
  TotalPage = 1
  ItemPerPage = 250
  ItemList = []
  Filter = ''
}

export default PagedItemList;