import Vue from "vue";
import ParagraphEdit from "./paragraph-edit.component.vue";

// import child components
Vue.component("paragraphEdit", ParagraphEdit);

const ParagraphListEditComponent = {
  props: ["paragraphList"],
  methods: {
    /**
     * moves the paragraph up/down one place
     * @param {*} par - the paragraph to move
     * @param {string} corr - direction of move (up/down) default: down
     * @returns - no return value
     */
    moveParagraph: function (par, corr) {
      console.log("moveParagraph");
      let c = 1;
      if (corr === "up") {
        c = -1;
      }
      var other = this.paragraphList.find((p) => {
        return p.order == par.order + c;
      });
      if (!other) {
        console.log(`NOT FOUND ${par.id}, ${corr}`);
        return;
      }
      other.order = par.order;
      par.order = par.order + c;
    },
    /**
     * toggles the delete state of given Paragraph
     * @param {*} par - the Paragraph to delete / undelete
     * @returns - no return value
     */
    deleteParagraph: function (par) {
      if (!par || !par.delete) {
        return;
      }
      par.delete = !par.delete;
    },
    /**
     * adds a paragaraph object to the paragraphList
     */
    addParagraph: function () {
      this.paragraphList.push({
        text: "",
        order: this.paragraphList.length + 1,
        id: -1 * (this.paragraphList.length + 1),
        delete: false,
      });
    },
    updateOrderTo5: function () {
      var l = this.paragraphList[0];
      l.order = 5;
    },
  },
  computed: {
    orderedParagraphs: function () {
      if (!this.paragraphList) {
        return [];
      }

      return this.paragraphList.sort((a, b) => {
        return a.order - b.order;
      });
    },
  },
};

export default ParagraphListEditComponent;
