import Axios from "axios";
import EnvironmenConfiguration from "../config";
import store from "../store/store";

class UserServiceClass {
  configuration = new EnvironmenConfiguration().getConfiguration();

  /**
   * get a list of all users from server
   */
  getUsersList = async function () {
    let result = await Axios.get(`/Account/Users`);
    if (result.data) {
      return result.data;
    }
    return null;
  };

  /**
   * checks if current user has role (does not call db)
   * @param {string} role role to check
   */
  isUserInRole = function (role) {
    let u = store.state.UserInfo;

    // .Net Framework delivers uppercase
    if (u && u.Roles) {
      var r = u.Roles.split(",");
      return r.includes(role);
    }
    // dotnet core delivers lowercase and roles as list
    if (u && u.roles) {
      return u.roles.includes(role);
    }
  };

  /**
   * gets user detail data from backend
   *
   * @param {string} id
   */
  getUserDetail = async function (id) {
    let result = await Axios.get(
      `/Account/User/${id}`
    );
    return result.data;
  };

  /**
   * gets a list of all available roles from server
   */
  getRoles = async function () {
    let result = await Axios.get(`/Account/Roles`);
    return result.data;
  };

  /**
   * saves a AgnhsUser to backend
   * @param {*} user
   */
  postUser = async function (user) {
    let u = {
      Id: user.id,
      Email: user.email,
      Username: user.username,
      IsLocked: user.isLocked,
      Roles: user.roles,
    };
    var data = JSON.stringify(u);

    let result = await Axios.post(
      `/Account/User`,
      data,
      { headers: { "Content-Type": "application/json" }, responseType: "json" }
    );
    return result.data;
  };

  /**
   * used for User registration with Password
   * @param {string} user
   * @param {string} pwd
   */
  registerUser = async function (user, pwd) {
    let u = {
      Email: user,
      Password: pwd,
    };
    var data = JSON.stringify(u);

    let result = await Axios.post(`/Account/Register`, data, {
      headers: { "Content-Type": "application/json" },
      responseType: "json",
    });

    return result;
  };

  /**
   * deletes the user
   * @param {string} uid
   */
  deleteUser = async function (uid) {
    let result = await Axios.delete(`${this.apiUrl}/Account/User/${uid}`);
    return result;
  };
}

export default UserServiceClass;
