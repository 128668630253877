import axios from "axios";
// import qs from 'qs';
import EnvironmenConfiguration from "../config";

class AuthenticateServiceClass {
    configuration = new EnvironmenConfiguration().getConfiguration();

    login = function (username, password) {
        let data = {
            username: username,
            password: password,
            grant_type: "password",
        };
        try {
            return axios
                .post(
                    `/login`,
                    data, //qs.stringify(data),
                    { headers: { "Content-Type": "application/json;charset=utf-8" } }
                )
                .then((resp) => {
                    if (resp.status == 200) {
                        return resp.data;
                    }
                });
        } catch (ex) {
            console.log(ex);
        }
    }
    register = function (username, email, password) {
        let data = {
            username: username,
            email: email,
            password: password
        };
        try {
            return axios
                .post(
                    '/register',
                    data,
                    { headers: { "Content-Type": "application/json;charset=utf-8" } }
                )
                .then((resp) => {
                    if (resp.status == 200) {
                        return resp.data;
                    }
                });
        } catch (ex) {
            console.log(ex);
        }
    };
}

export default AuthenticateServiceClass;
