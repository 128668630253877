import AgnhsDate from '../../../helper/AgnhsDate';

class NewsMiniComponentClass {
    data = function () {
        return {
            newsList: [],
            dateHelper: new AgnhsDate()
        };
    };
    
    created = async function () {
        console.log('NewsMiniComponent.created()')
        console.log(this.dateHelper.ShortMonth)
        this.newsList = await this.$store.state.newsService.getNews(3);
    };
}

export default new NewsMiniComponentClass();
