const ClickerComponent = {
  // props: ['count'],
  data: function () {
    return {
      count: 3,
    };
  },
  methods: {
    /**
     * moves the paragraph up/down one place
     * @param {*} par - the paragraph to move
     * @param {string} corr - direction of move (up/down) default: down
     * @returns - no return value
     */
    addOneHandler: function () {
      this.count++;
    },
  },
};

export default ClickerComponent;
