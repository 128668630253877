import { render, staticRenderFns } from "./event-list.component.vue?vue&type=template&id=7e4634ae&scoped=true&"
import script from "./event-list.component.js?vue&type=script&lang=js&"
export * from "./event-list.component.js?vue&type=script&lang=js&"
import style0 from "./event-list.component.scss?vue&type=style&index=0&id=7e4634ae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e4634ae",
  null
  
)

export default component.exports