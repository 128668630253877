import axios from "axios";
import EnvironmenConfiguration from "../config";

class FtpcontentServiceClass {
  configuration = new EnvironmenConfiguration().getConfiguration();

  /**
   *
   * @param {string} path - the path
   * @returns a list with the names of files and folders inside given path
   */
  getContent = async function (path) {
    let result = await axios.get(
      `/Ftp?path=${path}`
    );
    return result.data;
  };

  /**
   *
   * @param {string} path - the path where the file is located
   * @param {string} oldname - the file to rename
   * @param {string} newName - the new filename
   * @returns
   */
  renameFile = async function (path, oldname, newName) {
    let result = await axios.post(
      `/Ftp/rename?path=${path}&oldname=${oldname}&newname=${newName}`
    );
    return result;
  };

  /**
   *
   * @param {string} path - the path where the file is located
   * @param {string} name - the name of the file to delete
   * @returns
   *
   * @description the path is relativ starts at the root of the ftp - so / is the root path, /news the folder news etc.
   */
  deleteFile = async function (path, name) {
    let result = await axios.post(
      `/Ftp/delete?path=${path}&fileName=${name}`
    );
    return result.data;
  };

  /**
   *
   * @param {string} path - the path where the file is located
   * @param {*} file - the file data
   * @param {*} newName - the new data if not the file.name should be used
   * @returns
   */
  uploadFile = async function (path, file, newName) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("path", path);
    if (!newName || newName === "" || newName.length == 0) {
      formData.append("targetName", file.name);
    } else {
      formData.append("targetName", newName);
    }

    let result = await axios.post(
      `/Ftp/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result;
  };
}

export default FtpcontentServiceClass;
