export default {
    //UserInfo: { name: 'mc_nos', age: 14 },
    name: "HelloWorld",

    data: function () {
        return {
            ConnectionStatus: false,
            AuthStatus: 0,
            IsBeta: 1,
            scrollY: 0

        };
    },

    props: {
        componentKey: 0,
    },
    created: function () {
        try {
            document.addEventListener('scroll', this.navbarShrink);
            this.$store.state.connectionService.getConnectionStatus().then((r) => {
                this.ConnectionStatus = r.status == 1;
                this.IsBeta = r.version !== 'PROD'
            });
            this.checkAuth();
        } catch (error) {
            console.log(error);
            this.ConnectionStatus = false;
            this.AuthStatus = 0;
        }

    },
    computed: {
        UserInfo() {
            try {
                console.log(this.$store.getters.getUserInfo)
                return this.$store.getters.getUserInfo;
            } catch (error) {
                console.log(error);
                return {}
            }

        },
        UserAdminRole() {
            try {
                return this.$store.state.userService.isUserInRole("UserAdmin");

            } catch (error) {
                return false;
            }

        },
        NewsAdminRole() {
            try {
                return this.$store.state.userService.isUserInRole("NewsAdmin");
            } catch (error) {
                console.log(error);
                return false;
            }

        },
        IsLoggedIn() {
            try {
                return this.$store.getters.getUserInfo.userName;
            }
            catch (error) {
                console.log(error);
                return false;
            }

        },
    },
    methods: {
        logout: function () {
            this.$store.commit("user", {});
            this.$store.commit("authToken", undefined);
        },
        checkAuth: function () {
            if (!this.UserInfo.name) {
                return;
            }
            this.AuthStatus = -1;
            this.AuthStatus = this.$store.state.connectionService
                .checkAuthentication()
                .then((r) => {
                    this.AuthStatus = r;
                    setTimeout(this.checkAuth, 300000);
                });
        },
        navbarShrink: function () {
            console.log('Scroll detected');
            const navbarCollabsible = document.body.querySelector("#mainNav");
            const navbarItemsList = document.body.querySelector('.dropdown-menu');
            if (!navbarCollabsible) {
                return;
            }
            if (window.scrollY === 0) {
                navbarCollabsible.classList.remove('agnhs-navbar-shrink');
                navbarItemsList.classList.remove('agnhs-navbar-shrink');
            } else {
                navbarCollabsible.classList.add('agnhs-navbar-shrink');
                navbarItemsList.classList.add('agnhs-navbar-shrink');
            }
        }
    },
};
