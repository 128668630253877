class MapConfiguration {
    url = 'https://geowms.bl.ch/'
    layers= [
            {
                name: 'Grundkarte farbig',
                layers: 'grundkarte_farbig_group',
                attribution: '<a href="https://www.baselland.ch/politik-und-behorden/direktionen/volkswirtschafts-und-gesundheitsdirektion/amt-fur-geoinformation/geoportal/geodienste/geowms-bl">© Amt für Geoinformation BL</a>'
            }
    ]
    mapOptions= {
        dragging: false,
        zoomControl: false,
        scrollWheelZoom: false,
    }
}

export default MapConfiguration;