import Axios from "axios";
import EnvironmenConfiguration from "../config";

class NewsServiceClass {
  // base url for backend
  configuration = new EnvironmenConfiguration().getConfiguration();

  /**
   * gets a list of News order by date desc
   * @param {int} id - if cnt == 0, all items are loaded
   *
   * returns a list of News
   */

  getNews = async function (cnt) {
      let params = { count: cnt };
    let result = await Axios.get(`/News`, {
      params,
    });

    return result.data;
  };

  /**
   * gets a single news entry by id
   * @param {int} id the id of the news to return
   */
  getNewsById = async function (id) {
    let result = await Axios.get(
      `/News/GetById?id=${id}`
    );

    return result.data;
  };

  /**
   * sends a put with a news data to server
   *
   * @param {News} news the news to store
   */
  updateNews = async function (news) {
    let data = JSON.stringify(news);
    return await Axios.put(
      `/News/${news.id}`,
      data,
      { headers: { "Content-Type": "application/json" }, responseType: "json" }
    );
  };

  /**
   * posts the news to the server (create a new one)
   * @param {*} news
   */
  createNews = async function (news) {
    let data = JSON.stringify(news);
    return await Axios.post(`/News`, data, {
      headers: { "Content-Type": "application/json" },
      responseType: "json",
    });
  };
}

export default NewsServiceClass;
