import Axios from "axios";
import EnvironmenConfiguration from "../config";

class LogServiceClass {
  configuration = new EnvironmenConfiguration().getConfiguration();

  logInfo = function (message) {
    let data = {
      Message: message,
      Level: "Info",
      Error: undefined,
    };

    Axios.post(`/Log`, JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    }).then(
      (r) => {
        console.log(r);
        return true;
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  };
}

export default LogServiceClass;
