import { render, staticRenderFns } from "./news-mini.component.vue?vue&type=template&id=5e730e91&"
import script from "./news-mini.component.js?vue&type=script&lang=javascript&"
export * from "./news-mini.component.js?vue&type=script&lang=javascript&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports