const pflegegebiete = [
    {
        nid: 10,
        id: 'Boeschmattbaechli',
        name: 'Böschmattbächli',
        imgurl: '/preview/Boeschmattbaechli_preview.jpg',
        minitext: 'Der untere Teil des Böschmattbächli im Bereich der Zytglogge',
        imageList: [
            { id: 1001, url: '/image/Boeschmattbaechli200205_01.jpg' }
        ],
        koordinaten: [47.46877, 7.81099],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Böschmattbächli Bereich Zytglogge, angrenzend Magermatte"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Kleinfliessgewässer mit gut durchmischten Kraut- und Strauchsaum"
            },
            {
                name: "Pflegeziele",
                value: "Strauchschicht niedrig halten, Förderung schwacher Arten"
            },
            {
                name: "Pflegearbeiten",
                value: "Regelmässiger Schnitt der Erlen und Weiden, Magermatte mind. ein Schnitt"
            },
            {
                name: "Landbesitzerin",
                value: "Linksseitig Stiftung Zytglogge, rechtsseitig Umfahrungsstrasse Sissach (Kt. BL)"
            },
            {
                name: "Besonderes",
                value: "Bach wurde beim Bau Umfahrungsstrasse vollständig zerstört und bei \
                    Rekultivierung 1998 neu und grosszügiger wiederhergestellt."
            }
        ],
        dokumente: [
        ]

    },
    {
        nid: 100,
        id: 'Chienberg',
        name: 'Chienberg',
        imgurl: '/preview/Chienbergweiher_Preview.jpg',
        minitext: 'Weiher mit Meteowasserspeisung in einer Griengrube',
        imageList: [
            { id: 10001, url: '/image/Chienbergweiher200205_01.jpg' },
            { id: 10002, url: '/image/Chienbergweiher200905_01.jpg' },
            { id: 10003, url: '/image/Chienbergweiher200905_02.jpg' },
            { id: 10004, url: '/image/Chienbergweiher201803_01.jpg' },
        ],
        koordinaten: [47.46627, 7.82402],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Chienbergweiher (Koordinaten: 629.057 x 257.350)"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Weiher (mit Folienabdichtung und Meteowasserspeisung), alte Griengrube"
            },
            {
                name: "Pflegeziele",
                value: "Weihererhalt und Grube Strauchfrei halten"
            },
            {
                name: "Pflegearbeiten",
                value: 'Regelmässig entlanden und in Umgebung Strauchschnitt sowie 1-mal jährlich mähen, Grubenwände "roh" halten'
            },
            {
                name: "Landbesitzerin",
                value: "Bürgergemeinde"
            },
            {
                name: "Besonderes",
                value: `Wurde durch AGNHS 1995 (Leitung T. Wernli) erstellt. Im Jahr 2004 und
                    2008 Rodungsarbeiten in der und um die alte Grube, Glögglifrosch-Population, Grasfrosch, Erdkröte, Bergmolch.
                    2017/2018 sanfte "Gesamtsanierung" des Weihers. Wasser abgepumpt,
                    fast vollständig entlandet und Sedimente herausgeholt, Folie wieder zum
                    Schutze überdeckt, Wasser rechtzeitig eingefüllt (im Mandat von uns durch
                    Stiftung Ökojob, Wasserfüllung durch FW Sissach).`
            }
        ],
        dokumente: [

        ]
    },
    {
        nid: 150,
        id: 'Cholholz',
        name: 'Cholholz',
        imgurl: '/preview/Cholholz_preview.jpg',
        minitext: 'Weiher und Umgebung',
        imageList: [
            { id: 1501, url: '/image/Cholholz201411_01.jpg' },
            { id: 1502, url: '/image/Cholholz201703_01.jpg' },
        ],
        koordinaten: [47.47231, 7.82117],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Cholholz, Parz. 1357 (Koordinaten ca. 628.835 x 258.020)"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Weiher (mit Folienabdichtung und Meteowasserspeisung), alte Griengrube"
            },
            {
                name: "Pflegeziele",
                value: "Weihererhalt und Grube Strauchfrei halten "
            },
            {
                name: "Pflegearbeiten",
                value: 'Regelmässig entlanden und in Umgebung Strauchschnitt sowie 1 mal jährlich mähen, Grubenwände "roh" halten'
            },
            {
                name: "Landbesitzerin",
                value: "Bürgergemeinde"
            },
            {
                name: "Besonderes",
                value: "Neuerstellung 2009 mit Projektleitung und -umsetzung durch AGNHS, finanziert durch BG, EWG, BNV und AGNHS (Gesamtkosten 27`800.-). Soll\
                    als Vernetzungsweiher zwischen Isleten- und Chienbergweiher dienen.\
                    2017 wurde Bauschutt in den Weiher geleert. Löste landesweite Medienecho aus. Täter wurden gefunden und Strafanzeige gemacht. Anschliessend Instandstellung (Schutt herausgeholt)\
                    "
            }
        ],
        dokumente: [

        ]
    },
    {
        nid: 200,
        id: 'Griederland',
        name: 'Griederland',
        imgurl: '/preview/Griederland_preview.jpg',
        minitext: 'Griederland im Herzen von Sissach',
        imageList: [
            { id: 20001, url: '/image/Griederland199903_01.jpg' },
            { id: 20002, url: '/image/Griederland200002_01.jpg' },
            { id: 20003, url: '/image/Griederland200608_01.jpg' },
            { id: 20004, url: '/image/Griederland201709_01.jpg' },
            { id: 20005, url: '/image/Griederland201709_02.jpg' },
            { id: 20006, url: '/image/Griederland201709_03.jpg' },
        ],
        koordinaten: [47.46798, 7.81099],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Griederland bei Allmend"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Baumgarten"
            },
            {
                name: "Pflegeziele",
                value: "Erhalt historischer Baumgarten, teilweise freihalten des Bachbordes"
            },
            {
                name: "Pflegearbeiten",
                value: `Regelmässiger Baumschnitt, an Ergolzufer regelmässig "Fenster" freischneiden, Hauptarbeiten (Mähen und Ordnung halten) wird durch Werkhof\
                    EWG Sissach gemacht. Baumschnitt wird durch AGNHS organisiert (P. Buser)`
            },
            {
                name: "Landbesitzerin",
                value: "Einwohnergemeinde Sissach"
            },
            {
                name: "Besonderes",
                value: "Grüne Lunge in Sissach, wurde parallel zu Diegterbachrevitalisierung aufgewertet, Bepflanzung durch AGNHS, EWG Sissach und Öffentlichkeit 1999."
            }
        ],
        dokumente: [

        ]
    },
    {
        nid: 300,
        id: 'Luchern',
        name: 'Luchernweiher',
        imgurl: '/preview/Luchern_preview.jpg',
        minitext: 'Weiher als Ersatzmassnahme für Burgenrainreservoir',
        imageList: [
            { id: 30001, url: '/image/Luchern201403_01.jpg' },
            { id: 30002, url: '/image/Luchern201503_01.jpg' },
            { id: 30003, url: '/image/Luchern201701_01.jpg' },
            { id: 30004, url: '/image/Luchern201706_01.jpg' },
            { id: 30005, url: '/image/Luchern201904_01.jpg' }
        ],
        koordinaten: [47.48484, 7.81189],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Luchern, (Koordinaten 628.133 x 259.406)"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Weiher (mit Folienabdichtung und Meteowasserspeisung)"
            },
            {
                name: "Pflegeziele",
                value: "Weihererhalt, Staudenflurpflege"
            },
            {
                name: "Pflegearbeiten",
                value: "Regelmässig entlanden und in Umgebung Strauchschnitt sowie 1 mal jährlich mähen"
            },
            {
                name: "Landbesitzerin",
                value: "Bürgergemeinde Sissach"
            },
            {
                name: "Besonderes",
                value: 'Wurde als Ersatzmassnahme für "Lebensraumverbrauch Neubau Burgenrainreservoir" durch Forstrevier ca. 2011 gebaut. Anfrage durch EWG für Pflege durch AGNHS'
            }
        ],
        dokumente: [

        ]
    },
    {
        nid: 400,
        id: 'Moesletenweiher',
        name: 'Mösletenweiher',
        imgurl: '/preview/image/Moesletenweiher_preview.jpg',
        minitext: 'Mösletenweiher in Wintersingen',
        imageList: [
            { id: 40001, url: '/image/Moesletenweiher202009_03.jpg' },
            { id: 40002, url: '/image/Moesletenweiher202009_04.jpg' },
            { id: 40003, url: '/image/Moesletenweiher202009_05.jpg' },
            { id: 40004, url: '/image/Moesletenweiher202009_06.jpg' }
        ],
        koordinaten: [47.48586, 7.83288],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Mösleten, Wintersingen (Koordinaten: 629.714 x 259.530)"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Weiher ursprünglich mit Lehmabdichtung, 2020 Gesamterneuerung mit Folienabdichtung."
            },
            {
                name: "Pflegeziele",
                value: "Verhinderung Verlandung"
            },
            {
                name: "Pflegearbeiten",
                value: "regelmässig entlanden, Mindestwasserstand erhalten"
            },
            {
                name: "Landbesitzerin",
                value: "Parzelle 786 in Besitz AGNHS"
            },
            {
                name: "Besonderes",
                value: "Grasfrosch, Erdkröte, Bergmolch, im Gebiet auch Feuersalamander\
                    Gesamterneuerung 2020, finanziert zu 90% durch Ebenrainzentrum, Abt. Natur + Landschaft (Gebiet liegt in einem kantonal geschützten Naturschutzgebiet). Rest durch AGNHS"
            }
        ],
        dokumente: [
            {
                id: 40010,
                name: "Bauchronik Gesamterneuerung Mösletenweiher Wintersingen",
                jahr: 2020,
                text: `Die beiden Parzellen 786 und 790 wurde bei der Melioration Wintersingen der AGNHS zugeteilt. Beide Parzellen liegen nordexponiert und weisen dementsprechend feucht-nasse Matten 
                    auf. Sie weisen einen grösseren Anteil an Hecken und Bäumen sowie auch vernässte Kleinstrukturen auf. Im Jahre 2006 wurde beide in das kantonale Inventar geschützte Naturobjekte 
                    aufgenommen.
                    Durch die Meliorationsgesellschaft wurde auf der Parzelle 786 ein mit Lehm abgedichteter
                    Weiher erstellt. Dieser wurde 2000 durch die AGNHS entlandet. Aktuell war er wieder undicht
                    und sehr bewachsen. Die AGNHS entschied sich 2019 für eine Gesamterneuerung. In Absprache mit dem Kanton BL, Abt. Natur und Landschaft wurde das Vorgehen und auch die
                    Finanzierung abgesprochen.
                    Im November bis Dezember 2019 wurden die Hecken und Waldränder durch das Forstrevier
                    Sissach aufgelichtet. Die Koordination der Arbeiten lag bei der AGNHS. Ab Mitte Februar bis
                    Mitte März wurde die Weiher-Gesamterneuerung durch das Ingenieurbüro Götz umgesetzt.
                    Anschliessend wurde durch die Stiftung Ökojob die Wasserführung ab Weiher instand gestellt
                    und noch einige Räumarbeiten umgesetzt. Im April 2020 wurden noch einige Startpflanzen
                    gesetzt.
                    Alle Arbeiten wurden zu unserer vollsten Zufriedenheit umgesetzt. Die Gesamtkosten betrugen
                    19'242.- (ohne Rodungskosten), wovon der allergrösste Teil durch den Kanton getragen
                    wurde. Die AGNHS bedankt sich in dieser Stelle bei allen Beteiligten, insbesondere bei der
                    Fachstelle Natur und Landschaft des Kantons Basel-Landschaft.`,
                dokument: '/static/publikationen/Bauchronik Gesamterneuerung Mösletenweiher Wintersingen 2020.pdf'
            }
        ]
    },
    {
        nid: 500,
        id: 'Moesleten',
        name: 'Mösleten',
        imgurl: '/preview/Moesleten_preview.jpg',
        minitext: 'Mösleten in Wintersingen',
        imageList: [
            { id: 50001, url: '/image/Moesleten200505_01.jpg' },
            { id: 50002, url: '/image/Moesleten201801_01.jpg' },
            { id: 50003, url: '/image/Moesleten201801_02.jpg' },
            { id: 50004, url: '/image/Moesleten202009_02.jpg' },
            { id: 50005, url: '/image/Moesleten202009_03.jpg' },
            { id: 50006, url: '/image/Moesleten202009_01.jpg' }
        ],
        koordinaten: [47.48586, 7.83288],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Mösleten, Wintersingen"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Magerweide und Magermatte, Feuchtstaudenflur, Hecken, Orchideenstandort"
            },
            {
                name: "Pflegeziele",
                value: "Feuchtstaudenflur jährlich eine Hälfte mähen, Magermatte ein Grasschnitt ab\
                    15. Juli, regelmässiger Heckenschnitt, Magerweide verpachtet (Beweidungszeit und Bestosszahl durch Ökovertrag geregelt)"
            },
            {
                name: "Pflegearbeiten",
                value: "regelmässig entlanden, Mindestwasserstand erhalten"
            },
            {
                name: "Landbesitzerin",
                value: "Parzellen 786 und 790 in Besitz AGNHS (insgesamt 100.4a)"
            },
            {
                name: "Besonderes",
                value: "Besitz AGNHS, kantonale Ökobeiträge, Feldhasenhabitat, Bewirtschaftung:\
                    Pacht Parzelle 786 durch Hofgemeinschaft unter der Fluh, Sissach, Weideparzelle 790 wird in Pacht durch Fam. Thommen, Wintersingen (061 971 28 27)\
                    bewirtschaftet. Feb. 2006 und 2009 Rodungsarbeiten durch Stiftung Ökojob in\
                    unserem Auftrag. Im Jahr 2001 grosse Waldrandauflichtung durch Forstrevier\
                    Sissach in unserem Auftrag.\
                    Beide Parzellen wurden 2007 in das Inventar geschützter Naturobjekte des\
                    Kt. BL (Gebiet Chienberg-Rickerbacherfluh und Farnsberg) aufgenommen.\
                    2019/2020 wiederum Waldrandauflichtungen und Rodungsarbeiten auf beiden Parzellen durchgeführt. Durch Forstrevier Sissach, bezahlt von Ebenrainzentrum, Abt. Natur + Landschaft."
            }
        ],
        dokumente: [

        ]
    },
    {
        nid: 600,
        id: 'Rebberg',
        name: 'Rebberg',
        imgurl: '/preview/Rebberg_preview.jpg',
        minitext: 'Magermatten am Rebberg',
        imageList: [
            { id: 60001, url: '/image/Rebberg200302_01.jpg' },
            { id: 60002, url: '/image/Rebberg200302_02.jpg' },
            { id: 60003, url: '/image/Rebberg201104_01.jpg' },
            { id: 60004, url: '/image/Rebberg201406_01.jpg' },
            { id: 60005, url: '/image/Rebberg201411_01.jpg' },
            { id: 60006, url: '/image/Rebberg201902_01.jpg' },
            { id: 60007, url: '/image/Rebberg201905_01.jpg' }
        ],
        koordinaten: [47.47345, 7.80385],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Rebberg, verschiedene Parzellen"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Magermatten, Heckenanteile, Hochstammobst, alte Rebmauern, kommunale\
                    Naturschutzzone. Oberer Teil liegt in BLN-Gebiet. Etliche Flächen sind im Inventar TWW (Trockenwiesen und -weiden)."
            },
            {
                name: "Pflegeziele",
                value: "Erhalt Magermatten, Hochstämme und alte Mauern"
            },
            {
                name: "Pflegearbeiten",
                value: "Magermatten mähen (ab 15.Juli), Heckenschnitt und Heckenrodung, regelmässig Pflegeschnitt bei Hochstammbäumen, Mauern wenn notwendig ausbessern"
            },
            {
                name: "Landbesitzerin",
                value: "Parzellen 1392, 1956, 928, 933, 938, 945,962, 964, 965 und 974 in Besitz AGNHS (insgesamt 135.5 a)"
            },
            {
                name: "Besonderes",
                value: "kantonale Ökobeiträge für Pflegearbeiten, Bewirtschaftung und Baumpflege einiger Parzellen durch F. Schweizer in Auftragsarbeit, andere Parzellen Bewirtschaftung in Pacht durch T. Zbinden (1392, 933, 938, ab 2012, vorher A. Gasser), T. Spinnler (928, 974) und H.+ F. Messer (962, 964, 965).\
                    Seit ca. 2002 regelmässige Rodungsarbeiten in unserem Auftrag durch Stiftung Ökojob. Inventar Botanik vorhanden. Tagfalterinventar 2014. Etliche Flächen"
            }
        ],
        dokumente: [

        ]
    },
    {
        nid: 700,
        id: 'Reusli',
        name: 'Reusli',
        imgurl: '/preview/Reusli_preview.jpg',
        minitext: 'Weiher und Geschiebesammler Reusli',
        imageList: [
            { id: 70001, url: '/image/Reusli201211_01.jpg' },
            { id: 70002, url: '/image/Reusli201307_01.jpg' },
            { id: 70003, url: '/image/Reusli201510_01.jpg' },
            { id: 70004, url: '/image/Reusli201704_01.jpg' },
            { id: 70005, url: '/image/Reusli201704_02.jpg' },
            { id: 70006, url: '/image/Reusli201704_03.jpg' },
            { id: 70007, url: '/image/Reusli201712_01.jpg' }
        ],
        koordinaten: [47.45721, 7.81342],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Reusli, Parz. 1273"
            },
            {
                name: "Lebensraum / Objekt",
                value: 'Weiher (mit Folienabdichtung und Meteowasserspeisung), anstelle "Fichtenacker", Geschwemmsel- Geschiebesammler als Hochwasserschutz Gebiet\
                    Reusli (Bach geht weiter unten in Dole)'
            },
            {
                name: "Pflegeziele",
                value: "Weihererhalt und Unterhalt Geschiebesammler (letztes in Zusammenarbeit mit\
                        EWG), Staudenflurpflege"
            },
            {
                name: "Pflegearbeiten",
                value: "Regelmässig entlanden und in Umgebung Strauchschnitt sowie 1-mal jährlich mähen"
            },
            {
                name: "Landbesitzerin",
                value: "Bürgergemeinde Sissach"
            },
            {
                name: "Besonderes",
                value: "Neuerstellung 2010 mit Projektleitung und -umsetzung durch AGNHS, finanziert\
                    durch BG, EWG, BNV, Walder-Bachmann-Stiftung, NLK BL und AGNHS (Gesamtkosten ca. 75`000, davon 50`000.- Weiherbau). Soll als Vernetzungsweiher\
                    zwischen Weiher Wölflistein und allfälligen Weihern in Zunzgen sowie bestehenden Weihern in Tenniken dienen. Zusätzlich als Sensibilisierungsort im südlichen Teil der Gemeinde für Bevölkerung. Durch Miteinbzug Schulklasse wurde\
                    auch ein grosses Wildbienenhotel aufgestellt."
            }
        ],
        dokumente: [
            {
                id:70010,
                name: "Baudokumentation Reuslibiotop",
                jahr: 2010,
                text: `Am Anfang stand der Wunsch für die Schaffung neuer Lebensräume im Raum Sissach. Diesen
                    konkretisierten wir mit verschiedenen Projekten und gelangten für Unterstützung in der ersten
                    Phase an die EWG Sissach, welche uns ihre Unterstützung zusagte. Einer der Vorschläge war
                    dieses Weiherprojekt. Die BG Sissach, die Landbesitzerin an diesem Standort, zeigte sich sehr
                    kooperativ. Die Absprache- und Planungsphasen wurden mit der BG und EWG Sissach sowie
                    auch mit der Forstseite durchgeführt und das Projekt ausgearbeitet. Die Projektleitung wurde
                    durch die AGNHS, die Projektplanung und Bauleitung durch das Ingenieurbüro Götz gemacht.
                    Gleichzeitig wurde auch die Finanzierung sichergestellt und das Baugesuch aufgelegt. Im Winterhalbjahr 2009/ 10 wurden die Schlagarbeiten durch das Forstrevier Sissach durchgeführt.
                    Durch die Stiftung Ökojob wurde das Waldstück "aufgeräumt". Die Bauarbeiten wurden bis Mitte
                    März 2010 abgeschlossen (Fa. Ruepp). Anschliessend wurden durch die AGNHS Stauden und
                    durch das Forstrevier Sträucher gepflanzt. Ebenfalls wurden durch die AGNHS Wildstaudenblumensamen für "lehmige Ruderalflächen" angesät und aus dem Mösletenweiher (Wintersingen)
                    und dem Isletenweiher ein "Startbesatz" mit Erdkröte- und Grasfroschlaich sowie auch einigen
                    Insektenlarven und Wasserpflanzen gemacht.
                    Der Weiher ist gebaut, gespannt sind wir auf die zukünftige Entwicklung im und um den Weiher.
                    Wir hoffen, für die belebte Mitwelt ein "interessanter, wohnlicher" Lebensraum geschaffen zu
                    haben.`,
                dokument: '/static/publikationen/Baudokumentation Reuslibiotop 2010.pdf'
            }
        ]
    },
    {
        nid: 800,
        id: 'Schwalbenhaus',
        name: 'Schwalbenhaus',
        imgurl: '/preview/Schwalbenhaus_preview.jpg',
        minitext: 'freistehendes Schwalbenhaus am Bergweg',
        imageList: [
            { id: 80001, url: '/image/Schwalbenhaus201603_01.jpg' },
            { id: 80002, url: '/image/Schwalbenhaus201706_01.jpg' }
        ],
        koordinaten: [47.46787, 7.81320],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Anfänglich visavis Primarschulhaus, Parz. 285, Versetzung 2016 an Rheinfelderstrasse"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Schwalbenhaus (Mehlschwalben)"
            },
            {
                name: "Pflegeziele",
                value: "Nistangebot für Mehlschwalben"
            },
            {
                name: "Pflegearbeiten",
                value: "Regelmässig Nester säubern, anlocken mit Lockrufen zur Besiedlung."
            },
            {
                name: "Landbesitzerin",
                value: "Einwohnergemeinde Sissach"
            },
            {
                name: "Besonderes",
                value: "Neuerstellung 1. Quartal 2011, gesponsert durch Basellandschaftliche Gebäudeversicherung, an AGNHS als Schenkung. Diverse\
                    Nestsponsoren (Nest / 100.-). Das Schwalbenhaus wurde am ersten Standort nicht genutzt (da genügend Nistmöglichkeit am Primarschulhaus). 2016 wurde altes Restaurant Fluh abgerissen.\
                    Dortige Nistmöglichkeiten zerstört. Als Ersatz Schwalbenhaus gezügelt, welches sehr schnell dann von Schwalben angenommen\
                    wurde."
            }
        ],
        dokumente: [

        ]
    },
    {
        nid: 900,
        id: 'Strickrain',
        name: 'Strickrainweiher',
        imgurl: '/preview/strickrain_preview.jpg',
        minitext: 'Strickrainweiher als Ersatzmassnahme bei der Deponie',
        imageList: [
            { id: 90001, url: '/image/strickrain200606_01.jpg' },
            { id: 90002, url: '/image/Strickrain200708_01.jpg' },
            { id: 90003, url: '/image/strickrain200711_01.jpg' },
            { id: 90004, url: '/image/Strickrain201004_01.jpg' }
        ],
        koordinaten: [47.48291, 7.80114],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Strickrainweiher (Koordinaten: 627.325 x 259.190)"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Weiher (mit Folienabdichtung, Meteowasserspeisung)"
            },
            {
                name: "Pflegeziele",
                value: "Verhinderung Verlandung"
            },
            {
                name: "Pflegearbeiten",
                value: "regelmässig entlanden, Umgebung strauchfrei halten (1 mal jährlich mähen)"
            },
            {
                name: "Landbesitzerin",
                value: "Bürgergemeinde Sissach"
            },
            {
                name: "Besonderes",
                value: 'Erster Weiher wurde 1993 durch AGNHS (Leitung T. Wernli) mit Hilfe\
                    Gemeinde erstellt. Glögglifroschpopulation (Geburtshelferkröte), daneben Erdkröte, Grasfrosch, Bergmolch, viele Arten Wasserinsekten. 2007\
                    nach "Abfischete und Tierzüglete" in neuen unterliegenden Weiher aufgehoben und im Zuge der Erweiterung der Deponie mit Deponiegut Überschüttet. Räumungsaktion durch AGNHS.\
                    Aktueller Weiher wurde als Ersatzweiher wegen Deponieerweiterung im\
                    Jahr 2005 durch EWG und Bürgergemeinde neu gebaut, Glögglifrosch\
                    und andere Amphibien. Bepflanzung Frühjahr 2006 und 2007 Züglete\
                    Tiere vom alten Weiher in diesen durch AGNHS. Seit ca. 2010 Ringelnatternstandort.\
                    2017 ausgesetzte Fische mit Hilfe Elektroabfischung herausgeholt\
                    (grosse Goldfische und Karpfen). Unklar, ob wir alle erwischten.'
            }
        ],
        dokumente: [

        ]
    },
    {
        nid: 1000,
        id: 'Wolfsloch',
        name: 'Wolfsloch',
        imgurl: '/preview/Wolfsloch_preview.jpg',
        minitext: 'Wolfsloch, Böckten',
        imageList: [
            { id: 100001, url: '/image/Wolfsloch200712_01.jpg' },
            { id: 100002, url: '/image/Wolfsloch201303_01.jpg' },
            { id: 100003, url: '/image/Wolfsloch201703_01.jpg' },
            { id: 100004, url: '/image/Wolfsloch201703_02.jpg' },
            { id: 100005, url: '/image/Wolfsloch201803_01.jpg' },
            { id: 100006, url: '/image/Wolfsloch201803_02.jpg' },

        ],
        koordinaten: [47.46391, 7.82763],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Wolfsloch, Böckten (Koordinaten: 629.320 x 257.080)"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Quell- und Auengebiet mit einigen Weihern (Lehmabdichtung) in altem Ergolzmäander, Magermatte mit Hochstammobst, Umgehungsweg, Staudenflur"
            },
            {
                name: "Pflegeziele",
                value: "Erhalt offenem Auengebiet, richtiges Wasserregime, Staudenflur und Hochstämme"
            },
            {
                name: "Pflegearbeiten",
                value: "Magermatte (verpachtet) ein Schnitt ab 15. Juni, Weiher entlanden und Auengebiet regelmässig auflichten, Umgehungsweg mähen (Besucherlenkung), Pflegeschnitte Hochstämme"
            },
            {
                name: "Landbesitzerin",
                value: "Parzelle 352 Besitz AGNHS (10.57a), Parzelle 354 im Besitz EWG Sissach"
            },
            {
                name: "Besonderes",
                value: "Kantonales Naturschutzgebiet, Absprache mit Gemeinde über Mindestzulauf von 8 - 10 l Wasser aus Reservoir in Gebiet (Gemeinde nutzt dieses\
                        Wasser für Schwimmbad). Im Jahr 2008 Teilerneuerung Umgehungsweg\
                        durch Ökojob, 2002 Rodungsarbeiten durch Forstrevier Sissach in unserem\
                        Auftrag. Mattenbewirtschaftung Parzelle 354 durch Familie Itin, Hof Hinterhorn, Zunzgen. 1998 durch AGNHS 3 neue Weiher angelegt. Es bestehen\
                        Inventare über Botanik und Schnecken. Auch z.T. Wurminventar"
            }
        ],
        dokumente: [

        ]
    },
    {
        nid: 1100,
        id: 'Weidli',
        name: 'Weidli/Steingrüebli Zunzgen',
        imgurl: '/preview/Weidli_preview.jpg',
        minitext: 'Weidli/ Steingrübli Zunzgen',
        imageList: [
            { id: 110001, url: '/image/Steingrueebli201704_01.jpg' },
            { id: 110002, url: '/image/Steingrueebli201804_01.jpg' },
            { id: 110003, url: '/image/Weidli201110_01.jpg' },
            { id: 110004, url: '/image/Weidli201110_02.jpg' }
        ],
        koordinaten: [47.45186, 7.80017],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Weidli/ Steingrübli Zunzgen, Parz. 1650 und 1643/1641"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Ehemaliger Steinabbau mit offenen Felsbändern und Steingrube, dazu rund 12\
                    Aren Magerwiese. Geeigneter Lebensraum für Reptilien sowie Ruderalpflanzen.\
                    Dementsprechendes Potential für Insektenwelt."
            },
            {
                name: "Pflegeziele",
                value: "Felsbänder und Grube offen halten, Niederhecke, extensive Magerwiese, Staudenflur"
            },
            {
                name: "Pflegearbeiten",
                value: "Regelmässig 1-mal jährlich mähen (Magerwiese und Staudenflur), Hecke pflegen, Kontrolle von Brombeerwuchs und Neophyten, wird mit NVZ abgesprochen"
            },
            {
                name: "Landbesitzerin",
                value: "1650 (12.8 a) und 1643 (23.3 a, davon rund 10 a Magerwiese) Eigentum AGNHS, 1641 (36.6 a) EWG Zunzgen"
            },
            {
                name: "Besonderes",
                value: "Parz. 1650 durch Schenkung 2011 von W. Tschopp an AGNHS, 1641 durch\
                    AGNHS 2011 von Fam. Schelker abgekauft (Kosten Kauf plus Beurkundung\
                    usw. rund 8`500.-). 1641 kant. Schutzgebiet, 1650 und 1643 kommunales\
                    Schutzgebiet. In Absprache mit EWG, NVZ, kant. Behörde Rodung und freilegen\
                    abgesprochen und dies im Herbst 2011 umgesetzt (Rodungsrarbeiten 1641 und\
                    1643 durch Forstrevier, 1650 durch AGNHS). Baggereinsatz zum freilegen der\
                    Felsbänder und Gruben auf 1641 und 1643. Finanziert grösstenteils durch Kanton, N+L. 1650 durch AGNHS. BNV gab 3`000.- für finanz. Unterstützung. Parz.\
                    1643 ab 2012 in Pacht an B. Wagner, Zunzgen. NVZ baut Wildbienenhotel und Fussweg"
            }
        ],
        dokumente: [

        ]
    },
    {
        nid: 1200,
        id: 'Weidliweiher',
        name: 'Weidliweiher Zunzgen',
        imgurl: '/preview/Weidliweiher_preview.jpg',
        minitext: 'Weidli, Zunzgen',
        imageList: [
            { id: 120001, url: '/image/Weidliweiher201606_01.jpg' },
            { id: 120002, url: '/image/Weidliweiher201606_02.jpg' },
            { id: 120003, url: '/image/Weidliweiher201704_01.jpg' },
            { id: 120004, url: '/image/Weidliweiher201704_02.jpg' },
            { id: 120005, url: '/image/Weidliweiher201707_01.jpg' }
        ],
        koordinaten: [47.45094, 7.79988],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Weidli, Parz. 1650"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Weide mit offenem Felsenband. Geeigneter Lebensraum für Reptilien sowie Ruderalpflanzen. Weiher für Amphibien. In Nähe grosse Feuersalamanderpopulation"
            },
            {
                name: "Pflegeziele",
                value: "Weiherpflege mit Ziel, Verlandung zu verlangsamen."
            },
            {
                name: "Pflegearbeiten",
                value: "Weiherpflege mit Ziel, Verlandung zu verlangsamen."
            },
            {
                name: "Landbesitzerin",
                value: "1650 (12.8 a) AGNHS"
            },
            {
                name: "Besonderes",
                value: "Parz. 1650 durch Schenkung 2011 von W. Tschopp an AGNHS. 1650 kommunales Schutzgebiet. Bau initiiert durch ProNatura BL. Kosten 33'000.-. Beteiligungen von ProNatura, EWG Zungen, Kanton und AGNHS (4'000.-).Soll als\
                    Leitart Glögglifrosch anlocken. Pflege Umgebung durch Familie Hammer, Zunzgen."
            }
        ],
        dokumente: [
            {
                name: "Schlussbericht Weiher Weidli Zunzgen",
                jahr: 2015,
                text: `Die seit 2011 im Besitz der AGNHS befindliche Parzelle 1650, Zunzgen, weist sehr schöne offene Felsbänder auf. Genutzt wird die umliegende Weide durch Schafe.
                    Im Rahmen der geplanten Bachausdolung "Hardbächli" durch Pro Natura BL wurde der Wunsch
                    geäussert, neben dem Bach auch einen Weiher zu bauen. Pro Natura BL gelangte mit diesem
                    Wunsch an die AGNHS. Die Umsetzung wurde mit allen Betroffenen (Bewirtschafter, Landeigentümerin, Gemeindevertreter und Pro Natura) ausdiskutiert und allseitig begrüsst.
                    Die Projektleitung lag bei Pro Natura. Die Projektplanung und Bauleitung wurde durch das Ingenieurbüro Götz, die Bauarbeiten durch die Firma Aquaplant, Hansueli Hunziker gemacht. Die
                    AGNHS war bei allen Entscheiden mit vertreten. Nach Sicherstellung der Finanzierung (Gesamtkosten rund 32'000.-) wurde das Baugesuch eingereicht, die Bewilligung kam im September 2015. Die Bauarbeiten wurden in den Monaten September/Oktober 2015 umgesetzt, Ansaaten und Staudenbepflanzungen im Oktober 2015 und im Frühjahr 2016.
                    Der Weiher ist gebaut, gespannt schauen wir auf die zukünftige Entwicklung der belebten Mitwelt im und um den Weiher.`,
                dokument: '/static/publikationen/Schlussbericht Weiher Weidli Zunzgen.pdf'
            }
        ]
    },
    {
        nid: 1300,
        id: 'Islentenweiher',
        name: 'Isletenweiher',
        imgurl: '/preview/Isletenweiher_preview.jpg',
        minitext: 'Weiher bei der Isleten',
        imageList: [
            { id: 130001, url: '/image/Isletenweiher201403_01.jpg' },
            { id: 130002, url: '/image/Isletenweiher201904_01.jpg' },
            { id: 130003, url: '/image/Isletenweiher201907_01.jpg' },
            { id: 130004, url: '/image/Isletenweiher201907_02.jpg' },
            { id: 130005, url: '/image/Isletenweiher201907_03.jpg' },
            { id: 130006, url: '/image/Isletenweiher201907_04.jpg' }
        ],
        koordinaten: [47.47696, 7.81998],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Isletenweiher (Koordinaten: 628747 x 258.536)"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Weiher (mit Folienabdichtung, nur Meteowasserspeisung)"
            },
            {
                name: "Pflegeziele",
                value: "Verhinderung Verlandung"
            },
            {
                name: "Pflegearbeiten",
                value: "regelmässig entlanden, Strauchschnitt um Weiher herum"
            },
            {
                name: "Landbesitzerin",
                value: "Familie Grazioli, Fluhberg"
            },
            {
                name: "Besonderes",
                value: "Wurde im Jahr 1991 durch AGNHS (Leitung T. Wernli) mit Hilfe Gemeinde angelegt. Sehr artenreiches Laichgewässer mit Berg- und Fadenmolch, Grasfrosch, Erdkröte, Feuersalamander, Glögglifrosch (in Sammelaktion 2004 wurden von oberhalb des Weges 328 Grasfrösche, 195 Erdkröten, 773 Bergmolche, 39 Fadenmolche und 72 Feuersalamander von Anfangs März bis Anfangs April gesammelt und gezählt). 2008 Weiden gefüllt entlang Isletenbächli\
                        im Weiherbereich. 2015 Gesamterneuerung und -vergrösserung. Kosten\
                        28'000.-. Finanziert durch AGNHS, EWG, BG und J.+K. Mittner."
            }
        ],
        dokumente: [
            {
                name: "Projekt Gesamterneuerung Isletenweiher",
                jahr: 2015,
                text: `Der heutige Isletenweiher wurde 1991 durch die AGNHS unter grosser Unterstützung vom
                    Werkhof Sissach und vom Bauverwalter Friedel Treier gebaut. Seitens EWG wurden die Hauptkosten getragen (Grässenordnung 8'000.-). Die betroffenen Grundeigentümer Bürgergemeinde,
                    Frei und Einwohnergemeinde gab ihr Einverständnis`,
                dokument: '/static/publikationen/Historisches und Projekt Neubau Isletenweiher 2014.pdf'
            }
        ]
    },
    {
        nid: 1400,
        id: 'Isletenweghecke',
        name: 'Isletenweghecke',
        imgurl: '/preview/Isletenweghecke_preview.jpg',
        minitext: 'Hecke entlang dem Weg',
        imageList: [
            { id: 140001, url: '/image/Isletenweghecke200509_01.jpg' },
            { id: 140002, url: '/image/Isletenweghecke200509_02.jpg' },
            { id: 140003, url: '/image/Isletenweghecke200510_01.jpg' },
            { id: 140004, url: '/image/Isletenweghecke201304_01.jpg' },
            { id: 140005, url: '/image/Isletenweghecke201703_01.jpg' }
        ],
        koordinaten: [47.47604, 7.81664],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Isletenweghecke"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Nieder- und Mittelhecke"
            },
            {
                name: "Pflegeziele",
                value: "Erhalt Artenvielfalt in Hecke"
            },
            {
                name: "Pflegearbeiten",
                value: "alle 5- 7 Jahre grosszügiges `Stutzen` (2005 am Naturschutztag, hinterer\
                        Teil 2007), vorzu Heckenschnitt durch T. Mesmer (Auftrag durch AGNHS)"
            },
            {
                name: "Landbesitzerin",
                value: "Fam. Buser, Voregg, Bewirtschaftung durch Hofgemeinschaft unter der Fluh"
            },
            {
                name: "Besonderes",
                value: "Wurde durch AGNHS gepflanzt, angrenzende Fromentalmatte ist eine der\
                    schönsten Blumenmatten im Bann Sissach. Wird jährlich durch T. Messner gestutzt.\
                    Seit 1949 wurden durch AGNHS im Raume Sissach rund 2.5 km Hecken\
                    angepflanzt. Die Pflegearbeiten werden zum grossen Teil durch EigentümerInnen oder EWG durchgeführt."
            }
        ],
        dokumente: [

        ]
    },
    {
        nid: 1500,
        id: 'Isletenbaechli',
        name: 'Isletenbach',
        imgurl: '/preview/Isletenbach_preview.jpg',
        minitext: 'Renaturierter Isletenbach',
        imageList: [
            { id: 150001, url: '/image/Isletenbach200205_01.jpg' },
            { id: 150002, url: '/image/Isletenbach200210_01.jpg' }
        ],
        koordinaten: [47.47743, 7.82171],
        steckbrief: [
            {
                name: "Örtlichkeit",
                value: "Isletenbach, oberster Teil"
            },
            {
                name: "Lebensraum / Objekt",
                value: "Kleinfliessgewässer"
            },
            {
                name: "Pflegeziele",
                value: "Erhalt Strukturreichtum "
            },
            {
                name: "Pflegearbeiten",
                value: "Bachbord und -sohle pflegen"
            },
            {
                name: "Landbesitzerin",
                value: "BÜrgergemeinde, Familie Friedli, Familie Grazioli, EWG"
            },
            {
                name: "Besonderes",
                value: "Wurde in drei Aktionen durch EWG Sissach respektiv AGNHS ausgedohlt\
                    / freigelegt, zwei Bepflanzungsaktionen mit Kindern von Schulheim Leiern\
                    durchgefÜhrt, FeuersalamandergewÄsser. Oberer Teil benÖtigt besseres\
                    Wasserregime (2014 Drainageanschluss bei Hof durch EWG gelegt)."
            }
        ],
        dokumente: [

        ]
    }
]
export default pflegegebiete;