import Vue from "vue";
import data from "../../../assets/data/data.js";
import { LMap, LWMSTileLayer, LMarker, LPopup } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

Vue.component("l-map", LMap);
Vue.component("l-wms-tile-layer", LWMSTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-popup", LPopup);

import { Icon } from "leaflet";

 delete Icon.Default.prototype._getIconUrl;
 Icon.Default.mergeOptions({
   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
   iconUrl: require("leaflet/dist/images/marker-icon.png"),
   shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
 });

const UebersichtMapComponent = {
  data: function () {
    return {
      hotspotList: [],
      url: "https://geowms.bl.ch/",
      layers: [
        {
          name: "Grundkarte farbig",
          layers: "grundkarte_farbig_group",
          attribution: "WMS data: © Amt für Geoinformation BL",
        },
      ],
      mapOptions: {
        dragging: false,
        scrollWheelZoom: false,
      },
    };
  },
  mounted: function () {
    console.log(`uebersicht-map.component.js - not depricated`)
    this.hotspotList = data.pflegegebiete;
  },
};

export default UebersichtMapComponent;
