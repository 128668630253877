import Vue from "vue";
import DoSomething from "./dosomething.component.vue";
import Clicker from "./clicker.component.vue";
import ParagraphListComponent from "../paragraph/paragraphlist-edit.component.vue";

Vue.component("dosomething", DoSomething);
Vue.component("clicker", Clicker);
Vue.component("paraList", ParagraphListComponent);

export default {
  data: function () {
    return {
      paragraphList: [
        {
          id: 2,
          order: 1,
          text: "Order 1",
          delete: false,
        },
        {
          id: 102,
          order: 3,
          text: "Order 3",
          delete: false,
        },
        {
          id: 13,
          order: 2,
          text: "Order 2",
          delete: false,
        },
      ],
    };
  },
};
