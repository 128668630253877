const agnhsData = {
    imageList: [
        {
            name: "Fledermaus",
            area: "fledermaus",
            showOnStart: 1,
            link: '/static/Fledermaus/Fledermaus_1.jpg'
        },
        {
            name: "Fledermaus 2",
            area: "fledermaus",
            showOnStart: 1,
            link: '/static/Fledermaus/Fledermaus_2.jpg'
        },
        {
            name: "Fledermaus 3",
            area: "fledermaus",
            showOnStart: 1,
            link: '/static/Fledermaus/Fledermaus_3.jpg'
        },
        {
            name: "Fledermaus 4",
            area: "fledermaus",
            showOnStart: 1,
            link: '/static/Fledermaus/Fledermaus_4.jpg'
        },
        {
            name: "Fledermaus 5",
            area: "fledermaus",
            showOnStart: 1,
            link: '/static/Fledermaus/Fledermaus_5.jpg'
        },
        {
            name: "Fledermaus 6",
            area: "fledermaus",
            showOnStart: 1,
            link: '/static/Fledermaus/Fledermaus_6.jpg'
        },
        {
            name: "Inhalt Spyrenkasten",
            area: "ornithologie",
            showOnStart: 1,
            link: require('../image/InhaltSpyrenkasten.jpg')
        },
        {
            name: "ReinigungSpyrenkasten",
            area: "ornithologie",
            showOnStart: 1,
            link: require('../image/ReinigungSpyrenkasten.jpg')
        }
        ,
        {
            name: "Schwalbenhaus an der Rheinfelderstrasse",
            area: "ornithologie",
            showOnStart: 1,
            link: require('../image/Schwalbenhaus.jpg')
        },
        {
            name: "Schwalbennester",
            area: "ornithologie",
            showOnStart: 1,
            link: require('../image/Schwalbennester.jpg')
        },
        {
            name: "Waldkautz",
            area: "unsorted",
            showOnStart: 1,
            link: require('../image/Waldkautz.jpg')
        },
        {
            name: "Waldkautzfamilie",
            area: "ornithologie",
            showOnStart: 1,
            link: require('../image/Waldkautzfamilie.jpg')
        },
        {
            name: "Waldkautzkasten",
            area: "ornithologie",
            showOnStart: 1,
            link: require('../image/Waldkautzkasten.jpg')
        },
        {
            name: "Wasseramsel",
            area: "ornithologie",
            showOnStart: 1,
            link: require('../image/Wasseramsel.jpg')
        },
        {
            name: "Bienenhotel",
            area: "mnis",
            showOnStart: 1,
            link: require('../image/Bienenhotel.jpg')
        },
        {
            name: "Blumeninsel",
            area: "mnis",
            showOnStart: 1,
            link: require('../image/Blumeninsel.jpg')
        },
        {
            name: "Naturnaher Garten",
            area: "mnis",
            showOnStart: 1,
            link: require('../image/Naturnahergarten.jpg')
        },
        {
            name: "Steinmauer",
            area: "mnis",
            showOnStart: 1,
            link: require('../image/Steinmauer.jpg')
        },
        {
            name: "Wildblumeninsel im Sommer",
            area: "mnis",
            showOnStart: 1,
            link: require('../image/WildblumeninselSommer.jpg')
        },
        {
            name: "Bahnhof 2003",
            area: "heimat",
            showOnStart: 1,
            link: require('../image/bahnhof2003.jpg')
        },
        {
            name: "Alters- und PFlegeheim Mühlematt",
            area: "heimat",
            showOnStart: 1,
            link: require('../image/altersheim.jpg')
        },
        {
            name: "Maulbeerbäume",
            area: "heimat",
            showOnStart: 1,
            link: require('../image/maulbeerbaeume.jpg')
        },
        {
            name: "Reformierte Kirche",
            area: "heimat",
            showOnStart: 1,
            link: require('../image/refkirche.jpg')
        },
        {
            name: "Turm am Stebligerweg",
            area: "heimat",
            showOnStart: 1,
            link: require('../image/turm.jpg')
        },
        {
            name: "Untere Fabrik",
            area: "heimat",
            showOnStart: 1,
            link: require('../image/unterefabrik_01.jpg')
        },
        {
            name: "Untere Fabrik 2009",
            area: "heimat",
            showOnStart: 1,
            link: require('../image/unterefabrik2009.jpg')
        },
        {
            name: "Wacht",
            area: "heimat",
            showOnStart: 1,
            link: require('../image/wacht.jpg')
        },
        {
            name: "Sonnenkreuzung",
            area: "photo",
            showOnStart: 1,
            link: require('../image/HotelDuLion.jpg')
        },
        {
            name: "Sonnenkreuzung 02",
            area: "photo",
            showOnStart: 1,
            link: require('../image/Sonnenkreuzung02.jpg')
        },
        {
            name: "Brücke",
            area: "photo",
            showOnStart: 1,
            link: require('../image/bruecke.jpg')
        },
        {
            name: "Lieferwagen Eptinger",
            area: "photo",
            showOnStart: 1,
            link: require('../image/lieferwagenEptinger.jpg')
        },
        {
            name: "Ansicht Sissach",
            area: "photo",
            showOnStart: 1,
            link: require('../image/sissachAnsicht.jpg')
        },
        {
            name: "Wacht von Brücke",
            area: "photo",
            showOnStart: 1,
            link: require('../image/wacht02.jpg')
        },
        {
            name: "Instandstellung Trockenmauern",
            area: "denkmal",
            showOnStart: 1,
            link: require('../image/cover_trockenmauer.png')
        },
        {
            name: "Ebenrainbrunnstube",
            area: "denkmal",
            showOnStart: 1,
            link: require('../image/cover_ebenrainbrunnstube.png')
        },
        {
            name: "Das Margarethenbrünneli",
            area: "denkmal",
            showOnStart: 1,
            link: require('../image/cover_margrethenbruenneli.png')
        },
        {
            name: "Hofacker",
            area: "denkmal",
            showOnStart: 1,
            link: require('../image/cover_hofacker.png')
        },
        {
            name: "Untere und Obere Fabrik",
            area: "denkmal",
            showOnStart: 1,
            link: require('../image/cover_obere_untere_fabrik.png')
        },
        {
            name: "Vetterlibrunnstube",
            area: "denkmal",
            showOnStart: 1,
            link: require('../image/cover_bauchronik_vetterli.png')
        },
        {
            name: "Sissacher Trinkwasserversorgung",
            area: "denkmal",
            showOnStart: 1,
            link: require('../image/cover_trinkwasserversorgung.png')
        },
        {
            name: "Chronik der Ornithologie",
            area: "naturschutz",
            showOnStart: 1,
            link: require('../image/cover_Ornithologie.png')
        },
        {
            name: "Gesamterneuerung Isletenweiher",
            area: "naturschutz",
            showOnStart: 1,
            link: require('../image/cover_ProjektIsletenweiher.png')
        },
        {
            name: "Pflegegebiete und Aktivitäten - Vereinsportrait",
            area: "naturschutz",
            showOnStart: 1,
            link: require('../image/cover_vereinsaktivitaeten.png')
        },
        {
            name: "Tagfalter und Widderchen",
            area: "naturschutz",
            showOnStart: 1,
            link: require('../image/cover_tagfalterundwidderchen.png')
        },
        {
            name: "Trockenbiotope Raume Sissach",
            area: "naturschutz",
            showOnStart: 1,
            link: require('../image/cover_trockenbiotope.png')
        }
        ,
        {
            name: "Artenförderung Mehl- und Rauchschwalben",
            area: "naturschutz",
            showOnStart: 1,
            link: require('../image/cover_mehlundrauchschwalben.png')
        },
        {
            name: "Amphibienaktion Isletenweg",
            area: "naturschutz",
            showOnStart: 1,
            link: require('../image/cover_amphibienaktion.png')
        },
        {
            name: "Revitalisierung Diegterbach",
            area: "naturschutz",
            showOnStart: 1,
            link: require('../image/cover_diegterbach.png')
        },
        {
            name: "Isletenbächli",
            area: "naturschutz",
            showOnStart: 1,
            link: require('../image/cover_isletenbaechli.png')
        }
    ],
    veranstaltungen: [
        {
            id: '20201031',
            titel: 'Naturschutztag Wolfsloch',
            datum: '2020-10-31T09:00:00.0Z',
            wochentag: 'Samstag',
            tag: 31,
            monat: "Oktober",
            jahr: 2020,
            zeit: '9:00 Uhr',
            koordinaten: [47.46372, 7.82864],
            treffpunkt: 'Wolfsloch',
            paragraph: ['Der Naturschutztag der AGNHS findet dieses Jahr im Wolfsloch statt', 'Aufgrund von Corona wird eine Anwesenheitsliste geführt. Während den Arbeiten müssen die Mindestabstände berücksichtigt werden.'],
            teaser: 'Naturschutztag 2020 im Wolfsloch. Pflegeeinsatz an der Ergolz Richtung Böckten',
            links: [],
            anmeldung: {}
        },
        {
            id: '20201115',
            titel: 'Wasservogelzählung',
            datum: '2020-11-15T08:00:00.0Z',
            wochentag: 'Sonntag',
            tag: 15,
            monat: "November",
            jahr: 2020,
            zeit: '8:00 Uhr',
            koordinaten: [47.46588, 7.80575],
            treffpunkt: 'Die Wasservogelzählung kann leider nicht öffentlich stattfinden',
            paragraph: ['Die Wasservogelzählung findet am Samstag, 15. November 2020 statt.',
                'Dabei werden regelmässig die gängigen Enten, Graureiher, Bergstelzen, Wasseramseln, Eisvögel und ab und zu Gänsesäger und seltener Bekassinen gezählt.',
                'Aufgrund der Pandemielage, wird die Wasservogelzählung in einer kleinen Gruppe der AGNHS durchgeführt. Eine Teilnahme ist leider nicht möglich'],
            teaser: 'Halbjährliche Wasservogelzählung zwischen Liestal und Gelterkinden',
            links: [],
            anmeldung: {}
        }, {
            id: '20200927',
            titel: 'War schon vorbei bevor es angefangen hat',
            datum: '2020-10-11T20:00:00.0Z',
            wochentag: 'Samstag',
            tag: 27,
            monat: "September",
            jahr: 2020,
            zeit: '14:00 Uhr',
            koordinaten: [47.46372, 7.82864],
            treffpunkt: 'Vorbei ist vorbei',
            paragraph: ['blablabla', 'blas nochmer bla und bla'],
            teaser: 'Das ist ein Test um das zu testen',
            links: [],
            anmeldung: {}
        },
        {
            id: '20210117',
            titel: 'Wasservogelzählung',
            datum: '2021-01-17T08:00:00.0Z',
            wochentag: 'Sonntag',
            tag: 17,
            monat: "Januar",
            jahr: 2021,
            zeit: '8:00 Uhr',
            koordinaten: [47.46588, 7.80575],
            treffpunkt: 'Die Wasservogelzählung kann leider nicht öffentlich stattfinden',
            paragraph: ['Die Wasservogelzählung findet am Sonntag, 17. Januar 2021.',
                'Dabei werden regelmässig die gängigen Enten, Graureiher, Bergstelzen, Wasseramseln, Eisvögel und ab und zu Gänsesäger und seltener Bekassinen gezählt.',
                'Aufgrund der Pandemielage, wird die Wasservogelzählung in einer kleinen Gruppe der AGNHS durchgeführt. Eine Teilnahme ist leider nicht möglich'],
            teaser: 'Halbjährliche Wasservogelzählung zwischen Liestal und Gelterkinden',
            links: [],
            anmeldung: {}
        },
        {
            id: '20210417',
            titel: 'Wildpflanzenmarkt',
            datum: '2021-04-17T09:00:00.0Z',
            wochentag: 'Samstag',
            tag: 17,
            monat: "April",
            jahr: 2021,
            zeit: '9:00-13:00 Uhr',
            koordinaten: [47.46393, 7.81253],
            treffpunkt: 'Hauptstrasse 60, Liegenschaft R. Häfelfinger, vis-a-vis CHeesmeyer',
            paragraph: ['Freude an Wilnis im Garten oder auf dem Balkon?',
                'Wir bieten zahlreiche Wildstauden und Kräuter an.', 'Dieses Jahr wird das Angebot am Markt reduziert sein, und es werden keine Wildsträucher angeboten.', 'Kommen Sie trotzdem und lassen Sie sich inspierieren!',
                'Alternativ können Pflanzen und Sträucher auf der Homepage von Pro Nature (https://www.pronatura-bl.ch/de/wildpflanzenmaerkte) bestellt und in Sissach im Wildpflanzendepot abgeholt werden.'],
            teaser: 'Freude an Wilnis im Garten oder auf dem Balkon?',
            links: [],
            anmeldung: {}
        },
        {
            id: '20210501',
            titel: 'Abgesagt - Ornithologische Morgenexkursion',
            datum: '2021-05-01T08:00:00.0Z',
            wochentag: 'Samstag',
            tag: 1,
            monat: "Mai",
            jahr: 2021,
            zeit: '',
            koordinaten: [],
            treffpunkt: 'Abgesagt',
            paragraph: ['Absage',
                'Der Vorstand der Arbeitsgemeinschaft hat beschlossen, in diesem Jahr auf die traditionel gut besuchte Ornithologische Morgenexkursion zu verzichten.', 'Die Pandemie lässt im Moment nicht zu, dass grössere Veranstaltungen zu verantworten sind.'],
            teaser: 'Absage - Der Vorstand hat beschlossen dieses Jahr auf die 1. Mai Exkursion zu verzichten. Wir bitten um Verständnis',
            links: [],
            anmeldung: {}
        },
        {
            id: '20210508',
            titel: 'Abholung bestellte Wildpflanzen (Pro Natura Baselland)',
            datum: '2021-05-01T09:00:00.0Z',
            wochentag: 'Sam./Son.',
            tag: 8,
            monat: "Mai",
            jahr: 2021,
            zeit: '9:00-17:00 Uhr',
            koordinaten: [47.46043, 7.80411],
            treffpunkt: 'Wildpflanzendepot Sissach',
            paragraph: ['Haben Sie auf der Homepage von Pro Natura Baselland Pflanzen bestellt?',
                'Die Pflanzen, welche per Homepage von Pro Natura Baselland (https://www.pronatura-bl.ch/de/wildpflanzenmaerkte) bestellt wurden, können am Samstag, 8. Mai und Sonntag, 9. Mai zwischen 9:00Uhr und 17:00Uhr beim Wildpflanzendepot abgholt werden.'],
            teaser: 'Haben Sie auf der Homepage von Pro Natura Baselland Pflanzen bestellt?',
            links: [{
                link: 'https://www.pronatura-bl.ch/de/wildpflanzenmaerkte',
                text: 'Einladung'
            }
            ],
            anmeldung: {}
        },
        {
            id: '20210819',
            titel: 'Präsentation der Wahl- und Abstimmungsergebnisse',
            datum: '2021-08-19T18:30:00.0Z',
            wochentag: 'Donnerstag',
            tag: 19,
            monat: "August",
            jahr: 2021,
            zeit: '18:30 Uhr',
            koordinaten: [47.45730, 7.81269],
            treffpunkt: 'Bierkellerhütte',
            paragraph: ['Nachdem die JV 2021 schriftlich stattfinden musste, freuen wir uns, alle Vereinsmitglieder zu einer Veranstaltung einladen zu können!',
                'Nicht nur die Bekanntgabe der Ergebnisse der diesjährigen Jahresversammlung stehen auf dem Programm. Die neu gewählten Vorstandsmitglieder stellen sich vor und für Interessierte wird es eine Begehung des Reussli-Biotopes geben. Ein Apéro wird von der AGNHS organisiert zusätzlich kann Selbstgebrachtes auf dem Feuer vor der Hütte gegrillt werden.', 'Bereits im Voraus bedanken wir uns für Ihre Anmeldung an jv2021@agnhs.ch und freuen uns auf einen regen Austausch an unserem Anlass.'
            ],
            teaser: 'Präsentation der Wahl- und Abstimmungsergebnisse - Nur für Vereinsmitglieder. Anmeldung erforderlich.',
            links: [{
                link: '/static/jv2021/Einladung_Ergebnisbekanntgabe72JV.pdf',
                text: 'Einladung'
            }],
            anmeldung: {
                email: 'jv2021@agnhs.ch'
            }
        },
        {
            id: '20210901',
            titel: 'Absage - Totholz Exkursion',
            datum: '2021-09-25T18:00',
            wochentag: '',
            tag: '',
            monat: 'Aug/Sept',
            jahr: '2021',
            zeit: '',
            treffpunkt: 'abgesagt',
            paragraph: ['Im Jahresprogramm wurde eine Totholz Exkursion für August angekündet. Aufgrund der aktuellen Situation kann diese leider nicht stattfinden.'],
            teaser: 'Im Jahresprogramm wurde eine Totholz Exkursion für August angekündet. Aufgrund der aktuellen Situation kann diese leider nicht stattfinden.',
            anmeldung: {
                email: ''
            },
            links: []
        },
        {
            id: '20210918',
            titel: 'Clean Up Day',
            datum: '2021-09-18T09:00:00',
            wochentag: 'Samstag',
            tag: '18',
            monat: 'September',
            jahr: '2021',
            zeit: '9:30 Uhr',
            treffpunkt: 'Exotic Sissach',
            koordinaten: [47.46736, 7.81157],
            teaser: 'Am Clean up Day 2021 schickt auch die AGNHS eine Gruppe los und sammelt den Abfall in und um die Bäche ein.',
            paragraph: ['Die Arbeitsgemeinschaft für Natur- und Heimatschutz Sissach (kurz AGNHS) nimmt sich mit ihren HelferInnen bei der Säuberungsaktion am Clean Up Day den Bach vor.',
                'Gemeinsam begehen wir dieses fragile Biotop und tragen dort den Abfall zusammen. Treffpunkt ist um 9h30 bei der Exotic, Gummistiefel oder andere Schuhe für die Bachbegehung müssen selbst mitgebracht werden. Bei schlechten Wetterverhältnissen wird ein Alternativgebiet gesäubert.', 'Die Gruppengrösse ist beschränkt, wir danken Ihnen daher für eine Voranmeldung unter cleanupday@agnhs.ch. Kinder bis 12 Jahre dürfen nur in Begleitung einer Erwachsenen Person teilnehmen.'],
            anmeldung: {
                email: 'cleanupday@agnhs.ch'
            },
            links: [{
                link: 'https://igsu.ch/de/clean-up-day/home/',
                text: 'IG saubere Umwelt'
            }]
        },
        {
            id: '20211030',
            titel: 'Naturschutztag',
            datum: '2021-10-30T09:15:00',
            wochentag: 'Samstag',
            tag: '30',
            monat: 'Oktober',
            jahr: '2021',
            zeit: '9:15 Uhr',
            treffpunkt: 'Isletenweiher',
            koordinaten: [47.47693, 7.81988],
            teaser: 'Naturschutztag - Pflegen der Weiher im Cholzholz und Isleten',
            paragraph: ['Die Weiher im Cholholz und Isleten benötigen regelmässige Pflege. Am Naturschutztag wird rund um die Weiher die Sträucher zurückgeschnitten, Verunreinigungen im Teich entfernt und ein Verlanden der Wasserflächen verhindert.',
                'Die Arbeiten werden gemeinschaftlich durchgeführt und es gibt für jeden etwas zu tun.', 'Nach getaner Arbeit findet sich immer noch Zeit für eine Verpflegung vom Feuer und Getränke welche die AGNHS für alle zur Verfügung stellt',
                'Der Treffpunkt ist um ca. 9.15 Uhr beim Isletenweiher (9:06 Uhr Bus ab Sissach Bahnhof, fahrt bis Haltestelle Voregg)'],
            anmeldung: {
                email: ''
            },
            links: []
        },
        {
            id: '20210912',
            titel: 'Geologische Vormittags-Exkursion',
            datum: '2021-09-12T09:00',
            wochentag: 'Sonntag',
            tag: '12',
            monat: 'September',
            jahr: '2021',
            zeit: '9:00',
            treffpunkt: 'Hof Rebacker in Sissach',
            koordinaten: [47.46477, 7.82536],
            teaser: 'Die Arbeitsgemeinschaft für Natur- und Heimatschutz führt eine geologische Exkursion im Raume Storchennest – Burgenrain – Diegterbach durch.',
            paragraph: ['Thema der Exkursion ist der Tafeljura mit seinen Brüchen und Bewegungen mit dem Einsinken des Oberrheingrabens und jüngeren Bewegungen nach der Ablagerung der Juranagelfluh. Es besteht Gelegenheit die Lockergesteine und Hangbildungen zu kennenzulernen. Eine weniger bekannte, riesige Rutschung in Thürnen von 1858 wird vorgestellt. Wir beleuchten auch die Grundwasserverhältnisse (regionales Wasserwerk Sissach- Wüeri) und wir besuchen eine Quellenlandschaft (Wolfsloch) oberhalb von Sissach. Sodann erklimmen wir den Hügel Burgenrain mit der alten Fortifikation. Wir sehen uns Aufschlüsse von Lias-Gesteinen bis Hauptrogenstein an. Zum Abschluss werfen wir einen Blick auf den Katarakt im Diegterbach bei der Kunsteisbahn Sissach (Bild). Urs Pfirter, versierter Geologe, wird uns bei diesem Rundgang fachkundig oben genanntes aufzeigen.', 'Sie alle sind herzlich zur Teilnahme an diesem Anlass eingeladen. Dieser findet am Sonntag 12. September 2021 statt. Der Treffpunkt ist beim Hof Rebacker in Sissach um 9h00 (keine Parkplätze, bitte in der Allmend parkieren und via Bergweg den Hof Rebacker erreichen). Ausrüstung: gutes Schuhwerk, ev. Hammer und Lupe, Regenschutz bei unsicherer Witterung. Infolge der aktuellen Situation wird am Anlass um Einhaltung der nötigen Abstände gebeten. Um ca. 12h00 endet die Exkursion beim Bahnhof Sissach.'],
            anmeldung: {
                email: ''
            },
            links: []
        },
        {
            id: '20220219',
            titel: 'Pflanzaktion beim Horandschürli',
            datum: '2022-02-19T10:00',
            wochentag: 'Samstag',
            tag: '19',
            monat: 'Februar',
            jahr: '2022',
            zeit: '10:00',
            treffpunkt: 'Horandschürli, zwischen Zelgli und Hintere Halde',
            koordinaten: [47.47767, 7.80592],
            teaser: 'Die Bürgergemeinde Sissach ist Eigentümerin des Horandschüerli und dem umliegenden Land. Seitens Bürgerrrat wurde die AGNHS für das organisieren eine Heckenpflanzung auf diesem Lande angefragt. Der Bewirtschafter ist ebenfalls einverstanden.',
            paragraph: ['Die Bürgergemeinde Sissach ist Eigentümerin des Horandschüerli und dem umliegenden Land. Seitens Bürgerrrat wurde die AGNHS für das organisieren eine Heckenpflanzung auf diesem Lande angefragt. Der Bewirtschafter ist ebenfalls einverstanden.',
                'Geplant ist nun die Anpflanzung einer 60m langen Hecke mit insgesamt 180 Sträuchern. Die Pflanzaktion findet am Samstag dem 19. Februar ab 10h00 statt. Bei Schlechtwetter oder gefrorenem Boden würde die Anpflanzung auf den Samstag, 26. Februar verschoben. Wir würden eine Verschiebung euch auf gleiche Weise mitteilen. ',
                'Wir freuen uns über Mithilfe und laden euch ein zu dieser Anpflanzaktion. Ihr benötigt gute Schuhe oder Stiefel. Werkzeug und auch die anschliessende Verpflegung wird durch uns organisiert. ',
                'Der Weg zum Pflanzplatz wird via Halde - Hintere Halde oder via Zelglihof (auf Hofhöhe rechtabgehender Weg nehmen).',
                'Wir freuen uns auf diese gemeinsame Pflanzaktion. Mit freundlichen Grüssen, namens AGNHS-Vorstand, Daniel Schmutz'
            ],
            anmeldung: {
                email: ''
            },
            links: []
        },
        {
            id: '20220501',
            titel: '1. Mai Exkursion',
            datum: '2022-05-01T07:00',
            wochentag: 'Sonntag',
            tag: '1.',
            monat: 'Mai',
            jahr: '2022',
            zeit: '7:00',
            treffpunkt: 'Schulhaus Bützenen',
            koordinaten: [47.46407, 7.81643],
            teaser: 'Ornithologische 1. Mai Exkursion',
            paragraph: ['Nach zwei Jahren findet endlich wieder einmal die traditionelle naturkundliche 1. Mai Morgenexkursion der AGNHS statt.', 'Unter fachkundiger Führung von Niklaus Lang wird uns die Natur, die Vogel- und Wildtierwelt in verschiedenen Lebensräumen rund um Sissach näher gebracht. Vom Schulhaus Bützenen in Sissach aus gehen wir in Richtung Bergweg.', 'Was für Tiere und Vögel sehen wir im Dorf? Welcher Vogel pfeift denn da? Richtung Storchennest und durchs Junkholz vorbei am Rebacker - Wen treffen wir am Waldrand und im Agrarland? – Vom Kienberghof gehen wir wieder runter zurück ins Dorf', 'Zu dieser rund 2-stündigen Exkursion sind Sie alle ganz herzlich eingeladen, selbstverständlich mit Ihren Kindern. ', 'Wir treffen uns um 07h00 in Sissach beim Schulhaus Bützenen (Bushaltestelle). Die Exkursion findet bei jeder Witterung statt.'],
            anmeldung: {
                email: ''
            },
            links: []
        },
        {
            id: '20220423',
            titel: 'Wildpflanzen Märt',
            datum: '2022-04-23T09:00',
            wochentag: 'Samstag',
            tag: '23.',
            monat: 'April',
            jahr: '2022',
            zeit: '9:00 bis 13:00',
            treffpunkt: 'Hauptstrasse 60, Liegenschaft Häfelfinger',
            koordinaten: [47.46393, 7.81252],
            teaser: 'Wildplfanzen-Märt in Sissach',
            paragraph: ['Am Märt werden rund 50 verschiedene Wildblumen, über 15 Sträucherarten und viele Kräuter angeboten'],
            anmeldung: {
                email: ''
            },
            links: []
        },
        {
            id: '20220601',
            titel: '73. Jahresversammlung AGNHS',
            datum: '2022-06-01T19:00',
            wochentag: 'Mittwoch',
            tag: '1.',
            monat: 'Juni',
            jahr: '2022',
            zeit: '19:00 bis 21:00',
            treffpunkt: 'Bierkellerhütte Sissach',
            koordinaten: [47.45730, 7.81269],
            teaser: 'Ein öffentlicher Vortrag zum Thema Neophyten und das Neophytenprojekt BL ovn M. Bühler eröffnet den Abend. Anschliessend wird für die Mitglieder der AGNHS die Jahresversammlung durchgeführt ',
            paragraph: ['Michele Bühler ist Mitarbeiter bei ÖKO-JOB im Bereich Natur & Landschaft. Er ist gelernter Forstingenieur FH und ist zuständig für Naturschutzprojekte, Wildbienen-Nisthilfen und begleitet die Teilnehmer:innen als Bezugsperson während ihrem Einsatz bei ÖKO-JOB. Die AGNHS arbeitet seit mehreren Jahren bei der Pflege der Schutzgebiete in Sissach eng mit ÖKO-JOB zusammen. Die Stiftung ÖKO-JOB ist in der Arbeitsintegration tätig, mit Menschen, welche vom RAV, IV oder Sozialdienst angemeldet worden sind.',
                'Michele Bühler wird in einem Rund 30-minütigen Vortrag über Neophyten und das Neophytenprojekt BL berichten (speziell auf dem Gemeindegebiet Sissach), sowie die Zusammenarbeit zwischen AGNHS und ÖKO-JOB vorstellen.',
                'Die Traktandenliste, sowie der Jahresbericht 2021 (inkl. Jahresrechnung, Budget und Protokoll der letztjährigen schriftlichen Jahresversammlung) wurden per Post versendet.'],
            anmeldung: {
                email: ''
            },
            links: [{ text: 'Einladung und Jahresbericht', 'link': '/JV2022' }]
        },
        {
            id: '20220522',
            titel: 'Amphibien und ihr Lebensraum - ein Sonntag-Vormittag-Rundgang',
            datum: '2022-05-22T09:00',
            wochentag: 'Sonntag',
            tag: '22.',
            monat: 'Mai',
            jahr: '2022',
            zeit: '9:15',
            treffpunkt: 'Bushaltestelle Voregg',
            koordinaten: [47.47626, 7.81459],
            teaser: 'Am Sonntag-Vormittag führt die AGNHS eine naturkundliche Exkursion mit Schwerpunkt Amphibien durch.',
            paragraph: ['Am Sonntag-Vormittag führt die AGNHS eine naturkundliche Exkursion mit Schwerpunkt Amphibien durch. Dies im Raume Isleten - Chienberghof. Tom Wernli, Mitglied der kantonalen Amphibiengruppe wird durch die Exkursion führen. Die Route wird via Isletenweiher – Vetterlibrunnen – Cholholzweiher zum Chienbergweiher führen. Unterwegs werden neben den Amphibien weitere Tierarten sowie auch botanische Besonderheiten thematisiert. Die zu sehenden Amphibien werden vor Ort bestimmt und deren Eigenarten sowie auch deren Lebensraum nähergebracht. Für Diskussionen wird es genügend Platz haben. Sie alle sind herzlich zur Teilnahme an diesem Anlass eingeladen. Dieser findet am Sonntag 22. Mai 2022 statt. Der Treffpunkt ist die Bushaltstelle Voregg um 9h15. Der Wintersingerbus fährt um 9h06 Uhr ab Bahnhof Sissach.'],
            anmeldung: {
                email: ''
            },
            links: []
        },
        {
            id: '20220625',
            titel: 'Exkursion zur Feldscheune Hintere Egg mit Sensekurs',
            datum: '2022-06-25T07:30',
            wochentag: 'Samstag',
            tag: '25.',
            monat: 'Juni',
            jahr: '2022',
            zeit: '7:30',
            treffpunkt: 'Bahnhof Sissach',
            koordinaten: [47.46261, 7.81303],
            teaser: 'Am Samstag 25. Juni 2022 findet ein gemeinsamer Exkursionstag der Vereine "Erlebnisraum Tafeljura" und "Verein Baselbieter Feldscheunen" statt. Dies in Sissach. Im letzten Jahr haben wir zusammen mit dem Verein Baselbieter Feldscheunen und der Besitzerfamilie die Feldscheune Obere Guscht instand gestellt. In diesem Jahr wird nun die Feldscheune Hinteri Egg durch den Feldscheunenverein renoviert.',
            paragraph: ['Am Samstag 25. Juni 2022 findet ein gemeinsamer Exkursionstag der Vereine "Erlebnisraum Tafeljura" und "Verein Baselbieter Feldscheunen" statt. Dies in Sissach. Im letzten Jahr haben wir zusammen mit dem Verein Baselbieter Feldscheunen und der Besitzerfamilie die Feldscheune Obere Guscht instand gestellt. In diesem Jahr wird nun die Feldscheune Hinteri Egg durch den Feldscheunenverein renoviert.',
                'Am Vormittag wird ein Mähkurs mit Sense auf einer Parzelle der AGNHS durchgeführt. Anleiten wird Felix Schweizer (mit unser Unterstützung).', 'Alle Interessierte sind zur Teilnahme an diesem Anlass herzlich eingeladen. Auf beiliegenden Flyer finden sich die Details. Aus organisatorischen Gründen ist eine Anmeldung erforderlich.', 'Wir freuen uns auf ein Treffen mit euch. Freundliche Grüsse, namens AGNHS-Vorstand, Daniel Schmutz'],
            anmeldung: {
                email: 'baumgartner@tafeljura.ch'
            },
            links: [{ 'text': 'Flyer Exkursion mit allen Details', 'link': '/static/news/20220625_ExkursionFeldscheune.pdf' }]
        },
        {
            id: '20220907',
            titel: 'Abheben für die Biodiversität – Insekten-Flugsimulator',
            datum: '2022-09-07T18:00',
            wochentag: 'Mittwoch',
            tag: '7.',
            monat: 'Sept',
            jahr: '2022',
            zeit: '18:00',
            treffpunkt: 'Ebenrain',
            koordinaten: [47.46241, 7.80390],
            teaser: 'Schlüpfen Sie in die Haut eines Schmetterlings und fliegen Sie über Blumenwiesen!',
            paragraph: ['Schlüpfen Sie in die Haut eines Schmetterlings und fliegen Sie über Blumenwiesen! BirdLife Schweiz hat zu ihrem 100-jährigen Jubiläum eine Wanderausstellung zum Thema Biodiversität mit Insekten-Flugsimulator auf Reisen durch die Schweiz geschickt. Organisiert wird der Aufenthalt in Sissach von der Arbeitsgemeinschaft für Natur- und Heimatschutz Sissach und dem Basellandschaftlichen Natur- und Vogelschutzverband. Neben dem Flugsimulator gibt es an sechs weiteren Stationen viel Spannendes rund um das Thema Biodiversität zu entdecken. Was für ein Tier ist eigentlich der Ameisenlöwe, wo versteckt sich das Hermelin und was macht künstliches Licht mit den Tieren? Die Ausstellung gibt die Antworten auf spielerische Weise und ist daher für die ganze Familie geeignet.',
                'Die Ausstellung kann zu folgenden Zeiten im Ebenrainzentrum Sissach besucht werden: ', 'Ebenraintag: Sonntag, 4. September 2022 ab 10 Uhr',
                'Anlass AGNHS: Mittwochabend, 7. September, zwischen 18 Uhr und 20.30 Uhr'],
            anmeldung: {
            },
            links: [{ 'text': 'Insektenflugsimulator bei Bird Life', 'link': 'https://www.birdlife.ch/de/content/weltpremiere-insektenflugsimulator-wird-lanciert' }]
        },
        {
            id: '20220910',
            titel: 'Pilzexkursion',
            datum: '2022-09-10T09:30',
            wochentag: 'Samstag',
            tag: '10.',
            monat: 'Sept',
            jahr: '2022',
            zeit: '9:30',
            treffpunkt: 'Tännligarten',
            koordinaten: [47.46813, 7.82475],
            teaser: 'Wie ein Netz spannen sie sich unteririsch in unserem Boden und verbinden Wurzeln von Bäumen und anderen Pflanzen',
            paragraph: ['Wie ein Netz spannen sie sich unteririsch in unserem Boden und verbinden Wurzeln von Bäumen und anderen Pflanzen. Gehen Symbiosen mit ihnen ein – was ein geben und nehmen ist, von welchem Pilz und Pflanze profitiert, oder nisten sich als Parasit bei einem lebenden Wesen ein. Sie helfen organisches Material zu verarbeiten und spielen zum Beispiel bei der Verwesung von Holz eine wesentliche Rolle.',
                'Einige kann man essen und mit ihnen wunderbare Gerichte kochen, andere sollte man lieber nicht probieren. Das Reich der Pilze ist gross und ihre Funktionen im Ökosystem vielfältig.',
                'Um dieses Reich zu beleuchten, führt die Arbeitsgemeinschaft für Natur- und Heimatschutz Sissach am 10. September eine Pilzexkursion durch. Treffpunkt ist um 9.30 Uhr beim Tännligarten',
                'Achtung es sind keine Parkplätze vorhanden, bitte parkieren Sie im Dorf bei der Almend. Angeleitet von drei zertifizierte Pilzkontrolleur:innen vom Pilzverein Oberbaselbiet werden wir in kleinen Gruppen während etwa eine Stunde Pilze suchen und sammeln. Mit diesen treffen wir uns wieder im Tännligarten, legen die Funde aus und besprechen Fragen zu Wesen und Bedeutung der Pilze für das Ökosystem Wald.',
                'Die Veranstaltung wird ca. um 12 Uhr mit einem kleinen Apéro abgeschlossen.'],
            anmeldung: {
            },
            links: []
        },
        {
            id: '20220917',
            titel: 'Clean-Up-Day 2022',
            datum: '2022-09-17T09:30',
            wochentag: 'Samstag',
            tag: '17.',
            monat: 'Sept',
            jahr: '2022',
            zeit: '9:30',
            treffpunkt: 'Exotic',
            koordinaten: [47.46733, 7.81159],
            teaser: 'Die AGNHS wird, wenn es die Witterung zulässt, am Vormittag erneut eine Säuberungsaktion an der Ergolz durchführen. Hier sind wir froh über viele helfende Hände. Treffpunkt ist um 9h30 bei der Exotic, von dort aus werden wir gemeinsam angeschwemmte Material sammeln.',
            paragraph: ['Auch dieses Jahr macht die Gemeinde Sissach am Nationalen Clean-Up-Day mit und widmet sich an diesem Tag ganz dem Aufräumen und Säubern des Dorfes. Die AGNHS wird, wenn es die Witterung zulässt, am Vormittag erneut eine Säuberungsaktion an der Ergolz durchführen. Hier sind wir froh über viele helfende Hände. Treffpunkt ist um 9h30 bei der Exotic, von dort aus werden wir gemeinsam angeschwemmte Material sammeln. ',
                'Wetterangepasste Kleidung, Gartenhandschuhe und Gummistiefel (oder andere Schuhe für den Bach) müssen von den Teilnehmenden selbst mitgebracht werden. Das weitere Material (Müllsäcke etc.) wird von der AGNHS organisiert. Am Mittag wird der gesammelte Abfall in der Begegnungszone direkt getrennt und entsorgt. Für die Helfer:innen wird in der Begegnungzone ein Mittagessen offeriert. Informations-Stände, Spiele und Weiteres zum diesjährigen Thema «Recycling» laden zum Verweilen ein.',
                'Kinder unter 12 Jahren brauchen eine erwachsene Begleitperson. Bei schlechten Wetterverhältnissen werden wir nicht den Bach, sondern ein Alternativgebiet säubern.'],
            anmeldung: {
            },
            links: [{ 'text': 'Sissach.ch/Cleanupday', 'link': 'https://www.sissach.ch/aktuellesinformationen/1631522' }]
        },
        {
            id: '20220924',
            titel: 'Vom Leben und Schaffen der einfachen Leute in Sissach',
            datum: '2022-09-24T14:00',
            wochentag: 'Samstag',
            tag: '24.',
            monat: 'Sept',
            jahr: '2022',
            zeit: '14:00',
            treffpunkt: 'Bahnhof Sissach (Seite kath. Kirche)',
            koordinaten: [47.46234, 7.81149],
            teaser: 'Die Arbeitsgemeinschaft für Natur- und Heimatschutz führt einen sozial-historischen Dorfrundgang durch. ',
            paragraph: ['Die Arbeitsgemeinschaft für Natur- und Heimatschutz führt einen sozial-historischen Dorfrundgang durch.',
                'Der Rundgang durch Sissach steuert verschiedene Gebäude, Strassen und Plätze an, die mit dem Leben und Schaffen der einfachen Leute in Sissach zu tun haben. So werden beispielsweise die Beziehungen zwischen den Konfessionen zur Sprache kommen. Auch das Entstehen und Vergehen der Industrie wird Thema sein oder die Beziehungen zwischen der Gemeinde und dem Kanton. Der Rundgang wird von Ruedi Epple geführt. Als Sozialwissenschafter hat er am mehrbändigen Werk «Nah dran – weit weg» zur Geschichte des Kantons Basel-Landschaft mitgeschrieben. Er ist in Sissach aufgewachsen und kann seine historischen Ausführungen durch persönliche Erfahrung illustrieren.',
                'Sie alle sind herzlich zur Teilnahme an diesem Anlass eingeladen. Er findet am Samstag, 24. September 2022, statt und dauert rund zwei Stunden. Besammlung ist um 14h00 beim Bahnhof Sissach auf der Seite der katholischen Kirche. Die Teilnehmer:innen-Zahl ist begrenzt.',
                'Bitte melden Sie sich bis spätestens am 23. September 2022, 12h00, unter der Telefon-Nummer 061 971 74 66 oder unter daniel.schmutz@bluewin.ch an.'],
            anmeldung: {
                email: 'daniel.schmutz@bluewin.ch',
                telefon: '061 971 74 66'
            },
            links: [
            ]
        },
        {
            id: '20221029',
            titel: 'Naturschutztag auf dem Griederland',
            datum: '2022-10-29T09:00',
            wochentag: 'Samstag',
            tag: '29.',
            monat: 'Okt',
            jahr: '2022',
            zeit: '9:00',
            treffpunkt: 'Griederland',
            koordinaten: [47.46766, 7.81119],
            teaser: 'Samstag, 29. Oktober ab 9h00 in Zusammenarbeit mit der Gemeinde/Werkhof Sissach. Ende ca. 13h00. Auch dort stehen Werkzeuge und Verpflegung für euch bereit. Arbeitsthemen werden dort Heckenpflege, Bau einer Trockenmauer sowie Vogelnistkasten zum zusammenbauen und mit nach Hause zu nehmen sein. Details finden sich nachstehend.',
            paragraph: ['Samstag, 29. Oktober ab 9h00 in Zusammenarbeit mit der Gemeinde/Werkhof Sissach. Ende ca. 13h00. Auch dort stehen Werkzeuge und Verpflegung für euch bereit. Arbeitsthemen werden dort Heckenpflege, Bau einer Trockenmauer sowie Vogelnistkasten zum zusammenbauen und mit nach Hause zu nehmen sein. Details finden sich nachstehend.',
                'Wir freuen uns sehr über eure Mithilfe. Namens AGNHS und Einwohnergemeinde, Daniel Schmutz und Nicole Itin',
                'Natur im Siedlungsraum pflegen – Naturschutztag Sissach',
                'Am Samstag, 29. Oktober, führt die Arbeitsgemeinschaft für Natur- und Heimatschutz Sissach (AGNHS) in Zusammenarbeit mit der Einwohnergemeinde Sissach den jährlichen Naturschutztag durch. Auf dem Griederland bei der Sissacher Allmend unterhält die Einwohnergemeinde Sissach einen historischen Baumgarten mit Hochstamm-Obstbäumen. Solche Baumgärten gehörten früher in vielen Gegenden der Schweiz zum vertrauten Bild der Kulturlandschaft. Obst zur Selbstversorgung wurde traditionell in der Nähe der Siedlungen angebaut. Der Lebensraum Obstgarten bildete einen fliessenden Übergang zwischen der Siedlung und dem offenen Kulturland. Die Kombination von locker stehenden Hochstammbäumen und artenreichem Grasland als Unterwuchs macht den Hochstamm- Obstgarten zu einem besonderen Lebensraum. So finden in Obstgärten unzählige Tiergruppen wie Vögel, Fledermäuse, Schläfer, Igel, Spinnen, Schmetterlinge, Wildbienen, Schwebefliegen, Käfer und weitere Insektengruppen Nahrung. Im Rahmen des Projektes Grüne Siedlung wertet die Gemeinde Sissach, unterstützt vom Kanton Baselland, ausgewählte kommunalen Flächen auf, um ihre ökologische Qualität zu steigern.',
                'Am diesjährigen Naturschutztag sind freiwillige HelferInnen gefragt, die die AGNHS und Gemeinde unterstützen möchten, diese «grüne Insel von Sissach» zu pflegen, die ufernahe Hecke entlang der Ergolz auszulichten und das Schnittgut zusammennehmen. Dadurch werden einheimische Stauden und Blumen mehr Platz bekommen. Das Schnittgut auf den Asthaufen dient Insekten und anderen Kleintieren als Schutz und Unterschlupf. Im Weiteren werden wir zusammen eine sechs Meter lange Trockenmauer bauen. Dies als Unterschlupf für Reptilien. Daneben besteht auch Möglichkeit, einen Vogelnistkasten zusammenbauen und diesen dann nach Hause zum Aufhängen mitzunehmen. Für den Bausatz für diese Kästen sind wir besorgt.',
                'Wir treffen uns am Samstag, 29. Oktober, ab 9 Uhr, im Griederland vis-à-vis des Spielplatzes Allmend. Verpflegung und Werkzeug werden von der AGNHS und der EWG bereitgestellt. Eurerseits machen der Witterung angepasste Kleider und allenfalls Arbeitshandschuhe Sinn. Nach getaner Arbeit sind alle HelferInnen zum Mittagessen vom Grill eingeladen. Der Anlass findet bei jeder Witterung statt.'],
            anmeldung: {
                email: 'info@agnhs.ch',
                telefon: '061 971 74 66'
            },
            links: []
        },
        {
            id: '20230315',
            titel: 'Jahresversammlung',
            datum: '2023-03-15T19:30',
            treffpunkt: 'Cheesmeyer Sissach',
            koordinaten: [47.46361, 7.81258],
            teaser: 'Am Mittwoch, 15. März 2023 findet die Jahresversammlung der AGNH Sissach statt. Ein öffentlicher Vortrag über die Welt der Moose wird die Veranstaltung einläuten, danach findet die Jahresversammlung für die Vereinsmitglieder statt.',
            paragraph: ['Am Mittwoch 15. März 2023 findet die Jahresversammlung der Arbeitsgemeinschaft für Natur und Heimatschutz Sissach statt. ',
                'Wie jedes Jahr, wird zu Beginn ein öffentlicher Vortrag stattfinden. Das diesjährige Thema sind Moose.',
                'Danach wird der Vorstand die Vereinsmitglieder durch die Jahresversammlung führen.'
            ],
            image: "20230315_JV_Moos.png",
            anmeldung: {

            },
            links: []
        },
        {
            id: '20230421',
            titel: 'Historischer Dorfrundgang',
            datum: '2023-04-21T19:00',
            wochentag: 'Freitag',
            tag: '21.',
            monat: 'April',
            jahr: '2023',
            zeit: '19:00',
            treffpunkt: 'Cheesmeyer-Bistro (Hauptstrasse 55), Sissach',
            koordinaten: [47.4636, 7.81254],
            teaser: 'Am Freitag 21. April führt sie Kurt Häring durch das historische Sissach und erzählt über die Geschichte von Sissach',
            paragraph: [
                'Tag für Tag geht man durch sein Dorf, meint, jede Ecke und jedes Haus zu kennen. Wie ist das aber mit der Wacht? Wozu diente diese ursprünglich? Und was hat der Teichweg mit einem Teich zu tun und das Heidengässli mit Heiden? Welches ist wohl das älteste Haus in Sissach?',
                'Wer sich einmal etwas genauer mit solchen Fragen beschäftigt, schaut das Dorf, in dem er sich jeden Tag so selbstverständlich bewegt, plötzlich mit anderen Augen an.',
                'Die Arbeitsgemeinschaft für Natur und Heimatschutz Sissach (AGNHS) ladet zu einem solchen Dorfrundgang ein. Kurt Häring wird dabei all diese Fragen beantworten und vieles mehr zur Dorfgeschichte erzählen.',
                'Der Rundgang findet am Freitag 21. April ab 19h00 statt. Start ist beim Cheesmeyer-Bistro und dauert etwa eineinhalb Stunden. ',
                'Eine Anmeldung ist erforderlich. Die Maximal-Teilnehmer:innenzahl liegt bei 30.'
            ],
            anmeldung: { email: "veranstaltung@agnhs.ch" },
            links: []
        },
        {
            id: '20230422',
            titel: 'Wildpflanzenmarkt',
            datum: '2023-04-22T09:00',
            wochentag: 'Samstag',
            zeit: '9:00',
            treffpunkt: 'Strichcode',
            koordinaten: [47.46394, 7.81249],
            teaser: 'Am Samstag, 22. April 2023 findet der jährliche Wildpflanzenmarkt in Sissach statt',
            paragraph: ['Am Samstag, 22. April 2023 findet der jährliche Wildpflanzenmarkt in Sissach statt.',
                'Am Märt werden rund 70 verschiedene Wildblumen, über 15 Sträucher und viele Kräuter angeboten. Der Markt ist von 9:00 Uhr bis 13:00 Uhr geöffnet.'],
            anmeldung: {},
            links: []
        },
        {
            id: '20230429',
            titel: 'Sägäsä Kurs',
            datum: '2023-04-29T08:30',
            wochentag: 'Samstag',
            tag: '29.',
            monat: 'April',
            jahr: '2023',
            zeit: '8:30',
            treffpunkt: 'Wiese unterhalb Isletenweiher',
            koordinaten: [47.47644, 7.81960],
            teaser: 'Urs Chrétien führt einen Sensemähkurs in Sissach durch.',
            paragraph: [
                'Das Arbeitsinstrument wird seit dem Mittelalter in der Landwirtschaft eingesetzt. Wiesen und Getreide wurden mit der Sense, im Baselbiet Sägäsä genannt, gemäht. Vor etwa 50 Jahren fand die Ablösung des traditionellen Werkzeuges durch motorisierte Alternativen, wie Motormäher und Mähdrescher, statt.',
                'Seit wenigen Jahren ist wieder ein zunehmendes Interesse an dieser sanften Technik des Mähens zu beobachten. In privaten Gärten und im Naturschutz wird vermehrt mit der Sense gemäht, denn so werden Insekten und Kleintiere weniger verletzt oder getötet. Das Schnittgut wird auf Artenreichen Wiesen idealerweise nach dem Mähen noch einige Tage liegen gelassen, sodass die Pflanzen ihre Samen abwerfen können.',
                'Damit das Mähen mit der Sense Spass macht, muss diese regelmässig gewetzt und gedengelt werden. Beim Arbeiten mit der Sense, ist nicht wie vielleicht erst vermutet die Kraft massgebend, sondern viel mehr die Technik.',
                'Urs Chrétien zeigt am 29. April in einem halbtägigen Kurs wie das Mähen mit der Sense richtig ausgeführt wird. Der Kurs beginnt um 8h30 auf der Wiese unterhalb des Isletenweihers. Anreise mit dem Bus ab Bahnhof Sissach (8h06) bis zur Haltestelle Voregg.',
                'Sensen und Wetzstein werden zur Verfügung gestellt. Wer eine gut gepflegte Sense besitzt, kann diese mitnehmen. Wichtig sind robuste hohe Schuhe. Der Kurs ist kostenlos und findet bei (fast) jedem Wetter statt.',
                'Am Mittag werden wir ein Feuer machen, die Teilnehmenden sind eingeladen etwas zum Grillieren mitzubringen. Aufgrund beschränkter Plätze bitten wir um Voranmeldung bis zum 24. April bei info@agnhs.ch. Mitglieder der AGNHS werden zuerst berücksichtigt.'
            ],
            anmeldung: { email: "info@agnhs.ch" },
            links: []
        },
        {
            id: '20230501',
            titel: '1. Mai Exkursion',
            datum: '2023-05-01T07:00',
            wochentag: 'Montag',
            tag: '1.',
            monat: 'Mai',
            jahr: '2023',
            zeit: '7:00',
            treffpunkt: 'Beim Tennisplatz Grienmatt, Sissach',
            koordinaten: [47.47030, 7.79984],
            teaser: 'Ornithologische 1. Mai Exkursion',
            paragraph: ['Auch dieses Jahr findet die traditionelle naturkundliche 1. Mai Morgenexkursion der AGNHS statt.',
                'Wieder findet unsere alljährliche naturkundliche Morgenexkursion statt vom 1. Mai statt. Unter fachkundiger Führung von Niklaus Lang wird uns die Natur, die Vogel- und Wildtierwelt in verschiedenen Lebensräumen rund um Sissach näher gebracht',
                'Von der Grienmatt aus gehen wir in Richtung Brunnmatt. Was sehen wir in der Kulturlandschaft? Wer bevorzugt Hochstammbäume? Durch d‘Tüfelschuchi laufen wir zum Strickrain. Wen hören wir im Wald singen und wer am Waldrand?',
                'Danach spazieren wir über s’Zelgli wieder Richtung Dorf, wo wir beim Ausgangspunkt eine Abschlussfragerunde machen.',
                'Wir treffen uns um 07h00 beim Tenniscenter Ergolz in der Grienmatt in Sissach. Die Exkursion findet bei jeder Witterung statt. Zu dieser rund 2.5h-stündigen Exkursion sind Sie alle ganz herzlich eingeladen, selbstverständlich mit Ihren Kindern. '],
            anmeldung: {
            },
            links: []
        },
        {
            id: '20230507',
            titel: 'essbare Pflanzen und Gewürze',
            datum: '2023-05-07T09:15',
            wochentag: 'Sonntag',
            tag: '7.',
            monat: 'Mai',
            jahr: '2023',
            zeit: '9:19',
            treffpunkt: 'Bushaltestelle Voregg, Sissach',
            koordinaten: [47.47621, 7.81461],
            teaser: 'Wir möchten Sie zu einer botanischen Exkursion einladen, bei der wir uns auf die Suche nach essbaren Pflanzen und Gewürzen begeben werden',
            paragraph: ['Wir möchten Sie zu einer botanischen Exkursion einladen, bei der wir uns auf die Suche nach essbaren Pflanzen und Gewürzen begeben werden',
                'Diese Exkursion soll Ihnen die Gelegenheit bieten, mehr über die Verwendung von Wildpflanzen als Nahrungsmittel, Nahrungsergänzungsmittel, Gewürze und Heilpflanzen zu erfahren. Unser Guide, Dr. Raphael Weber, ist ein erfahrener Pflanzenökologe und Feldbotaniker mit umfangreichem Wissen über die heimische Flora.',
                'Wir treffen uns am Sonntag, den 7. Mai 2023, um 9:15 Uhr an der Bushaltestelle Voregg (Bus-Linie 106). Während 3 Stunden werden wir uns mit den Merkmalen und der Verwendungen der Wildpflanzen als Heilpflanzen, Nahrungsmittel und Gewürze befassen.',
                'Bitte bringen Sie - wenn verfügbar - eine Handlupe, eine kleine Schaufel und ein Baumwollsäckchen mit. Die Exkursion findet bei jedem Wetter statt. Wir freuen uns auf Ihre Teilnahme!',
            ],
            anmeldung: {
            },
            links: []
        },
        {
            id: '20230618',
            titel: 'Sounding Soil',
            datum: '2023-06-18T10:00',
            wochentag: 'Sonntag',
            tag: '18.',
            monat: 'Juni',
            jahr: '2023',
            zeit: '10:00',
            treffpunkt: 'Kreuzung Iktenweg, Rebbergweg, Hofstettenweg',
            koordinaten: [47.47240, 7.80000],
            teaser: 'Springschwänze, Milben, Hundertfüssler, Asseln, Regenwürmer und viele weitere Tiere leben in den Böden unter uns. Wir schenken ihnen selten Aufmerksamkeit, ausser wir stossen per Zufall im Gartenbeet auf einen dieser Bewohner. Die Arbeitsgemeinschaft für Natur und Heimatschutz Sissach bietet gemeinsam mit dem Projekt SoundingSoil der Stiftung Biovision eine zweistündige Exkursion zum Thema Boden, Bodengeräusche und Boden als Ökosystem an',
            paragraph: ['Springschwänze, Milben, Hundertfüssler, Asseln, Regenwürmer und viele weitere Tiere leben in den Böden unter uns. Wir schenken ihnen selten Aufmerksamkeit, ausser wir stossen per Zufall im Gartenbeet auf einen dieser Bewohner.',
                'Die Arbeitsgemeinschaft für Natur und Heimatschutz Sissach bietet gemeinsam mit dem Projekt SoundingSoil der Stiftung Biovision eine zweistündige Exkursion zum Thema Boden, Bodengeräusche und Boden als Ökosystem an.',
                'Mit Mikrofonen können die Teilnehmenden die Geräuschkulisse, welche unterhalb der Erdoberfläche herrscht, entdecken. Bodenökosysteme haben einen vielfältigen Aufgabenbereich. Sie filtrieren, speichern und regulieren Wasser, bieten Lebensraum und stellen Nährstoffe für Pflanzen zur Verfügung.',
                'Was braucht es für einen gesunden Boden, der all diese Funktionen übernehmen kann und wie ist dieser aufgebaut? Denn unter der Erdoberfläche versteckt sich mehr als nur braune Erde.',
                'Dieser und weiteren Fragen gehen wir am 18. Juni mit Nicole Blasko, Projektmitarbeiterin, nach.',
                'Die Exkursion beginnt um 10 Uhr bei der Kreuzung Iktenweg, Rebbergweg, Hofstettenweg und endet um 12 Uhr unweit des Treffpunktes entfernt. Gutes Schuhwerk und Wetterangepasste Kleidung werden vorausgesetzt.'
            ],
            anmeldung: {
            },
            links: [{ 'text': 'Sounding Soil Projekt', 'link': 'https://www.biovision.ch/projekt/sounding-soil/' }]
        },
        {
            id: '20230613',
            titel: 'Archäologische Führung Sissacher Fluh',
            datum: '2023-06-13T18:15',
            wochentag: 'Dienstag',
            tag: '13.',
            monat: 'Juni',
            jahr: '2023',
            zeit: '-',
            treffpunkt: 'Bushaltestelle Sissacherflueh',
            koordinaten: [47.4845, 7.81415],
            teaser: 'Die Arbeitsgemeinschaft für Natur- und Heimatschutz Sissach ladet zu einer Führung zur Archäologie auf der Sissacher Flue ein.',
            paragraph: ['Die Arbeitsgemeinschaft für Natur- und Heimatschutz Sissach ladet zu einer Führung zur Archäologie auf der Sissacher Flue ein.',
                'Dass die Ebene über dem markanten Felsvorsprung der Sissacher Flue seit der Steinzeit begangen wurde und die Menschen auch in der Eisenzeit hier ihre Spuren hinterlassen haben, ist Vielen unbekannt. Und wann wurden die heute, als mittelalterlich angesprochenen, sichtbaren Mauern gebaut?',
                'Diesen und weiteren Fragen widmet sich eine kurze Wanderung, geführt durch Simone Kiefer, wissenschaftliche Mitarbeiterin bei der Archäologie Baselland.',
                'Bei der Tour von der Wintersingerhöhe bis zum Aussichtspunkt Sissacher Flue werden die Zeugnisse der Vergangenheit, welche in den 30er Jahren des letzten Jahrhunderts ausgegraben wurden, sichtbar gemacht und die daraus gewonnenen Erkenntnisse mit den neuesten Forschungen aus der Archäologie ergänzt, diskutiert und neu eingeordnet.',
                'Durch Einbezug weiterer archäologischer Spuren im Raum Sissach, soll ein einheitliches Bild zur Siedlungslandschaft entstehen.',
                'Die Führung findet am 13.6.2023 statt und dauert ca. 2 Std. Treffpunkt ist um 18.15 an der Bushaltestelle Sissacher Flue, Bus 106. Gutes Schuhwerk und Wandertüchtigkeit wird vorausgesetzt. Die Führung findet bei jeder Witterung statt.'
            ],
            anmeldung: {
            },
            links: []
        },
        {
            id: '20230916',
            titel: 'Clean-Up-Day ',
            datum: '2023-09-16T09:30',
            wochentag: 'Samstag',
            tag: '16.',
            monat: 'September',
            jahr: '2023',
            zeit: '9:30',
            treffpunkt: 'Exotic Sissach',
            koordinaten: [47.46740,7.81160],
            teaser: 'Abfallsammelaktion am Clean-Up-Day in Sissach',
            paragraph: [
                'Die AGNHS wird am Samstag, 16. September am Clean-Up-Day der Gemeinde Sissach teilnehmen und in diesem Rahmen mit einer Säuberungsaktion an der Ergolz mithelfen.',
                'Treffpunkt ist um 9h30 bei der Exotic.',
                'Von dort aus werden wir gemeinsam angeschwemmtes Material sammeln. Wetterangepasste Kleidung und Gummistiefel (oder andere Schuhe für in den Bach) müssen von den Teilnehmenden selbst mitgebracht werden.',
                'Das weitere Material (Müllsäcke etc.) wird von der AGNHS mitgebracht.',
                'Am Mittag werden wir in die Begegnungszone gehen und unsere gesammelten Schätze mit den anderen Sammelgruppen zusammentragen.',
                'Kinder unter 12 Jahren sollen in Begleitung einer erwachsenen Person teilnehmen.',
                'Bei schlechten Wetterverhältnissen werden wir nicht den Bach, sondern ein Alternativgebiet säubern.'
            ],
            anmeldung: {
            },
            links: [{ 'text': 'Homepage Gemeinde Sissach', 'link': 'https://www.sissach.ch/aktuellesinformationen/1631522' }]
        },
        {
            id: '20230922',
            titel: '75 Jahre AGNHS - Jubiläumsanlass',
            datum: '2023-09-22T17:30',
            wochentag: 'Freitag',
            tag: '22.',
            monat: 'September',
            jahr: '2023',
            zeit: '17:30',
            treffpunkt: 'Alte Metzg, Sissach',
            koordinaten: [47.46343, 7.81213],
            teaser: '75. Jahre AGNHS - ein Grund zu feiern',
            paragraph: ['Vor 75 Jahren wurde die AGNHS in Leben gerufen. Mit einem Jubiläumsanlass will die AGNHS der letzten 75 Jahren gedenken',
                'Programmpunkte:',
                '17:30 - Apéro',
                '18:30 - Begrüssung - Lina Ferrari',
                '18:40 - Gemeinde Sissach - Peter Buser',
                '18:50 - Heimatschutz - Issac Reber',
                'Verpflegung',
                '19:30 - Spoken Words - Daniela Dill',
                '19:45 - Vereinsleben - Daniel Schmutz',
                'Verpflegung',
                '20:30 - Vorstellung Festschrift Natur & Heimat - Matthias Manz',
                '20:45 - Spoken Words - Daniela Dill',
                'Gemütliches Zusammensein'
            ],
            anmeldung: {
            },
            links: [{ 'text': 'Flyer Jubiläumsveranstaltung', 'link': '/static/veranstaltungen/Flyer_Jubilaeum.pdf' }]
        }
    ]
}

export default agnhsData







