import sammlung from "../assets/data/Sammlung.json";
import PagedItemList from "../helper/PagedItemList";
import LogService from "./log.service";

class SammlungServiceClass {
  logService = new LogService();
  getListOfImages = function (pil) {
    if (pil.Filter.length > 2) {
      this.logService.logInfo(pil.Filter);
    }

    if (typeof pil != typeof PagedItemList) {
      console.log("Wrong item type");
    }

    if (!pil.ItemPerPage || pil.ItemPerPage < 1) {
      pil.ItemPerPage = 20;
    }
    if (pil.ItemPerPage > 100) {
      pil.ItemPerPage = 100;
    }

    if (!pil.Page || pil.Page < 1) {
      pil.Page = 1;
    }

    var filtered = [];
    if (pil.Filter && pil.Filter.length > 3) {
      var upperFilter = pil.Filter.toUpperCase().trim();
      filtered = sammlung.Sammlung.filter(
        (f) =>
          f.Jahr.toUpperCase() == upperFilter ||
          f.Ort.toUpperCase().indexOf(upperFilter) >= 0 ||
          f.Beschreibung.toUpperCase().indexOf(upperFilter) >= 0 ||
          f.BildNr.toUpperCase().indexOf(upperFilter) >= 0
      );
    } else {
      filtered = sammlung.Sammlung;
    }
    pil.TotalCount = filtered.length;
    pil.GrandTotal = sammlung.Sammlung.length;

    var maxPage = filtered.length / pil.ItemPerPage;
    pil.TotalPage = Math.ceil(maxPage);

    if (Math.ceil(maxPage) < pil.Page) {
      pil.Page = Math.ceil(maxPage);
    }

    //var start = (pil.Page - 1) * pil.ItemPerPage;

    // var r = filtered
    //     .slice(start, start + pil.ItemPerPage);
    pil.ItemList = filtered;
    return pil;
  };
}

export default SammlungServiceClass;
