export default {
    Username: "",
    Password: "",
    Email: "",
    Validate: false,
    Errormessage: "",
    authService: {},

    data: function () {
        return {
            Username: this.Username,
            Password: this.Password,
            Email: this.Email,
            Validate: this.Validate,
            Errormessage: this.Errormessage,
        };
    },

    created() {
        this.authService = this.$store.state.authenticateService;
    },

    computed: {

    },
    methods: {
        doRegister() {
            this.Validate = true;
            let vi = this;
            if (!this.Username) return;
            if (!this.Password) return;
            if (!this.Email) { return; }

            this.authService.register(this.Username, this.Email, this.Password ).then(
                (r) => {
                    console.log(r);
                },
                () => {
                    vi.Errormessage = "Oops";
                }
            );
        },
    },
};
