import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store'
import axios from 'axios';

import {
  BootstrapVue,
  IconsPlugin,
  NavbarPlugin,
  BContainer,
  BRow,
  BCol,
  BImg,
  BCarousel,
  BCarouselSlide,
} from 'bootstrap-vue';
import NavbarComponent from './components/navbar/navbar.vue';
import ClubHistoryComponent from './components/club/clubhistory.vue';
import VeranstaltungList from './components/event/event-list/event-list.component.vue';
import PflegeOverviewMap from './components/pflege/uebersicht-map/uebersicht-map.component.vue';
import NewsMiniList from './components/news/mini/news-mini.component.vue';
import Playground from './components/playground/playground.component.vue';
import Register from './components/register/register.component.vue';
import Start from './components/start/start.component.vue';

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(NavbarPlugin);

Vue.component('b-container', BContainer);
Vue.component('b-row', BRow);
Vue.component('b-col', BCol);
Vue.component('b-carousel', BCarousel);
Vue.component('b-carousel-slide', BCarouselSlide);
Vue.component('b-img', BImg);

Vue.config.productionTip = false;

Vue.component('navbar', NavbarComponent);
Vue.component('navbar', NavbarComponent);
Vue.component('navbar', NavbarComponent);
Vue.component('clubhistory', ClubHistoryComponent);
Vue.component('veranstaltungList', VeranstaltungList);
Vue.component('pflegeOverview', PflegeOverviewMap);
Vue.component('newsminiList', NewsMiniList);
Vue.component('playground', Playground); // testing stuff
Vue.component('register', Register);
Vue.component('start', Start);

// configure axios
axios.defaults.withCredentials = true;

// map config


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
