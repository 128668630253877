class AgnhsDate {
    /**
     * list of month abrrevation in german
     * */
    ShortMonth = ["Jan", "Feb", "März", "Apr", "Mai", "Juni", "Jul", "Aug", "Sept", "Okt", "Nov", "Dez"];
    LongMonth = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
    WeekDay = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
    formatedDate = function (date) {
        var d = new Date(date);
        return d.getDate().toString() + '. ' + this.ShortMonth[d.getMonth()] + '. ' + d.getFullYear().toString();
    }

    formatedLongDate = function (date) {
        var d = new Date(date);
        return d.getDate().toString() + '. ' + this.LongMonth[d.getMonth()] + ' ' + d.getFullYear().toString();
    }

    getWeekDay = function (date) {
        var d = new Date(date);
        return this.WeekDay[d.getDay()]
    }

    formatedTime = function (date) {
        var d = new Date(date);
        return ('00' + d.getHours()).slice(-2) + ":" + ('00' + d.getMinutes()).slice(-2);
    }
}

export default AgnhsDate;




